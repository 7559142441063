import { ShipmentService, ContainerService, UserService } from "Services";
import { WindowUtilities } from "Utilities";

declare var GlobalConfig: GlobalConfig;

class ContainerDropdownService {
  getItemsForContainer = (container: any) => {
    const { PartnerCode } = UserService.getSelectedCompanyObservable();
    const { JobReference } = container;
    const ContainerNumber = container.Number;
    let actions: any[];

    return {
      then: (callback: Function) => {
        return new Promise((resolve, reject) => {
          if (JobReference) {
            this.getBookingActionList(JobReference, container).then(actions => {
              callback(actions);
              resolve();
            });
          } else {
            ShipmentService.getLatestRequestIDForContainer(
              ContainerNumber,
              PartnerCode
            )
              .then((data: any) => {
                const path = `${GlobalConfig.ENV.ServiceWeb}/containers/drive_requests`;
                actions = [
                  {
                    label: '{{::"REQUESTTYPE_FETCHCNT" | translate}}',
                    onClick: (event: Event) => {
                      event.preventDefault();
                      this.openPopup(
                        `${path}?PrevReq=${data.RequestId}&container=${ContainerNumber}`,
                        data.RequestId
                      );
                    }
                  }
                ];
                callback(actions);
                resolve();
              })
              .catch(err => {
                const path = `${GlobalConfig.ENV.ServiceWeb}/containers/drive_requests`;
                actions = [
                  {
                    label: '{{::"REQUESTTYPE_FETCHCNT" | translate}}',
                    onClick: (event: Event) => {
                      event.preventDefault();
                      this.openPopup(
                        `${path}?&container=${ContainerNumber}`,
                        ContainerNumber
                      );
                    }
                  }
                ];
                callback(actions);
                resolve();
              });
          }
          callback([]);
        });
      }
    };
  };

  private getBookingActionList = (
    jobReference: string,
    container: Container
  ) => {
    return ShipmentService.getBookingActionsList().then((data: any) => {
      return this.filterActions(data, container).map((action: any) => {
        const popupUrl = ShipmentService.buildBookingActionQueryUrl(
          jobReference,
          action
        );
        return {
          label: `{{::"${action.Key}" | translate}}`,
          onClick: (event: Event) => {
            event.preventDefault();
            this.openPopup(popupUrl, jobReference);
          }
        };
      });
    });
  };

  private combine(actions: any, pickUp: any) {
    const divider: any = "divider";

    return [].concat(actions, divider, pickUp);
  }

  private openPopup = (url: string, windowName: string) => {
    WindowUtilities.openPopup(url, windowName, 1020, 750);
  };

  /**
   * Simple function that determines if an action is intended only for
   * containers as opposed to for bookings.
   * @param  {object} action      Action-Object returned from ShipmentService
   */
  private actionIsForContainer = (action: any) => {
    const hiddenTypes: any = {
      REQUESTTYPE_CHNGCUSTCLR: true,
      REQUESTTYPE_CLAIMREPORT: true
    };
    return !hiddenTypes[action.Key] && action.ForContainer === "1";
  };

  /**
   * filterActions filters the actions applicable on the given container to
   * determine which actions should be displayed in the dropdown
   * @param  {array} actions List of actions (or action types)
   * @return {array}         Filtered list of actions -- the actions to display
   */
  private filterActions = (actions: any, container: any) => {
    return actions.filter((action: any) => {
      return (
        this.actionIsForContainer(action) &&
        container.JobStatus >= action.RangeFrom &&
        container.JobStatus <= action.RangeTo
      );
    });
  };
}

export default new ContainerDropdownService();
