import * as React from "react";
import * as ReactDOM from "react-dom";

import Table from "Containers/container/Table";

export default class Supplies extends React.Component<any, any> {
  render() {
    return (
      <Table
        body={[
          {
            LABEL_BOOKING: "IMM130062",
            LABEL_DESCRIPTION: "SALT IN BULK",
            LABEL_PACKAGING_TYPE: "20DC",
            LABEL_NUMBERS_REMAINING: "20",
            LABEL_WEIGHT: "24520",
            LABEL_CONSIGNEE: "Nathan & Olsen hf.",
            LABEL_TA: "X",
            LABEL_SS: "-",
            LABEL_BL: "SWB",
            LABEL_TEG: "FCL"
          },
          {
            LABEL_BOOKING: "IMM110035",
            LABEL_DESCRIPTION: "PEPPER IN BULK",
            LABEL_PACKAGING_TYPE: "20DC",
            LABEL_NUMBERS_REMAINING: "33",
            LABEL_WEIGHT: "15205",
            LABEL_CONSIGNEE: "Nathan & Olsen hf.",
            LABEL_TA: "-",
            LABEL_SS: "-",
            LABEL_BL: "-",
            LABEL_TEG: "-"
          },
          {
            LABEL_BOOKING: "AFK212321",
            LABEL_DESCRIPTION: "LEAVES IN BULK",
            LABEL_PACKAGING_TYPE: "10AB",
            LABEL_NUMBERS_REMAINING: "0",
            LABEL_WEIGHT: "31000",
            LABEL_CONSIGNEE: "Nathan & Olsen hf.",
            LABEL_TA: "X",
            LABEL_SS: "-",
            LABEL_BL: "SWB",
            LABEL_TEG: "FCL"
          },
          {
            LABEL_BOOKING: "DSL412022",
            LABEL_DESCRIPTION: "-",
            LABEL_PACKAGING_TYPE: "-",
            LABEL_NUMBERS_REMAINING: "20",
            LABEL_WEIGHT: "-",
            LABEL_CONSIGNEE: "Nathan & Olsen hf.",
            LABEL_TA: "-",
            LABEL_SS: "-",
            LABEL_BL: "-",
            LABEL_TEG: "-"
          }
        ]}
        options={{
          LABEL_BOOKING: {
            width: "10%"
          },
          LABEL_DESCRIPTION: {
            width: "14%"
          },
          LABEL_PACKAGING_TYPE: {
            width: "15%"
          },
          LABEL_NUMBERS_REMAINING: {
            width: "10%"
          },
          LABEL_WEIGHT: {
            width: "10%"
          },
          LABEL_CONSIGNEE: {
            width: "15%"
          },
          LABEL_TA: {
            width: "7%"
          },
          LABEL_SS: {
            width: "7%"
          },
          LABEL_BL: {
            width: "7%"
          },
          LABEL_TEG: {
            width: "7%"
          }
        }}
      />
    );
  }
}
