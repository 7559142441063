import * as React from "react";
import styles from "./index.less";
import * as _ from "lodash";
import { Header, Grid, Input } from "semantic-ui-react";
import "ts-nameof";
import { NoEquipmentRequestState, RequestVM } from "./interfaces";
import {
  languages,
  ShipmentService,
  SamskipNotify,
  UserService,
  RequestService
} from "Services";
import NoEquipmentRequestForm from "./noEquipmentRequestForm";
import { DRIVE_DEPARTMENTS } from "Constants/DepartmentConstants";
import { SG_REQUEST_TYPES } from "Constants/RequestConstants";

const REQUESTNUMBER_REGEX = /^\d{8}$/;
const INITIAL_STATE = {
  previousRequest: null,
  previousRequestNumber: "",
  lastValidRequestNumber: "",
  isPreviousRequestLoading: false
} as NoEquipmentRequestState;

export default class NoEquipmentRequestPane extends React.Component<
  { lang: string },
  NoEquipmentRequestState
> {
  state = INITIAL_STATE;

  onSearchChange = (value: string) => {
    if (value.length <= 8) {
      this.setState({ previousRequestNumber: value });
    }
    if (
      REQUESTNUMBER_REGEX.test(value) &&
      this.state.lastValidRequestNumber !== value
    ) {
      this.setState({
        lastValidRequestNumber: "",
        isPreviousRequestLoading: true
      });
      RequestService.getRequestByRequestID(value, [
        SG_REQUEST_TYPES.DRREQCNTIMP,
        SG_REQUEST_TYPES.EMPTYCNTEXP,
        SG_REQUEST_TYPES.FETCHEMTYCNT,
        SG_REQUEST_TYPES.FETCFULLCNTEXP,
        SG_REQUEST_TYPES.TRUFWDIMPFCL
      ])
        .then((request: RequestVM) => {
          if (request.Payer.Id === DRIVE_DEPARTMENTS.SOTT) {
            this.setState({
              previousRequest: null,
              isPreviousRequestLoading: false
            });
            SamskipNotify.displayError("DRIVEREQ_SOTT_ERROR_MESSAGE");
          } else {
            this.setState({
              previousRequest: request,
              lastValidRequestNumber: value,
              isPreviousRequestLoading: false
            });
          }
        })
        .catch(ex => {
          this.setState({
            previousRequest: null,
            isPreviousRequestLoading: false
          });
          if (ex.status === 404) {
            SamskipNotify.displayError(languages("ERROR_REQUEST_NOT_FOUND"));
          }
          if (ex.status === 500) {
            SamskipNotify.displayError(
              languages("ERROR_INTERNAL_SERVER_ERROR")
            );
          }
        });
    }
  };

  onSubmit = () => {
    this.setState(_.cloneDeep(INITIAL_STATE));
  };

  getInputIcon = (): string => {
    if (
      REQUESTNUMBER_REGEX.test(this.state.previousRequestNumber) &&
      !this.state.isPreviousRequestLoading
    ) {
      if (
        this.state.previousRequest !== null &&
        this.state.previousRequest !== undefined
      ) {
        return "check";
      }
      return "x";
    }
    return "";
  };

  render() {
    const {
      isPreviousRequestLoading,
      previousRequestNumber,
      lastValidRequestNumber,
      previousRequest
    } = this.state;

    return (
      <>
        <Grid celled stackable className={styles.noEquipmentPane}>
          <Grid.Row centered columns={1}>
            <Grid.Column width={16}>
              <Header as="h3">
                <label className={styles.boxTitle}>
                  {languages("LABEL_DRIVEREQUEST")}
                </label>
              </Header>
              <Input
                value={previousRequestNumber}
                type="number"
                onChange={(e: any, data: any) => {
                  this.onSearchChange(data.value);
                }}
                placeholder={languages("LABEL_DRIVEREQUEST_PLACEHOLDER")}
                id="requestReference"
                loading={isPreviousRequestLoading}
                iconPosition="left"
                icon={this.getInputIcon()}
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {REQUESTNUMBER_REGEX.test(lastValidRequestNumber) && (
          <NoEquipmentRequestForm
            className={
              !REQUESTNUMBER_REGEX.test(previousRequestNumber) ? "u-hidden" : ""
            }
            request={previousRequest}
            onSubmit={this.onSubmit}
          />
        )}
      </>
    );
  }
}
