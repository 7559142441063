import * as React from "react";
import * as ReactDOM from "react-dom";
import { $uiRouter } from "Bootstrap/angular";
import { UISref } from "@uirouter/react";
import * as Autosuggest from "react-autosuggest";
import { reaction } from "mobx";
import SamskipResource from "SamskipResource";

import { TranslationService, UserService, ContainerService } from "Services";
import { languages } from "Services/TranslationService";
import GeneralLoader from "Utilities/GeneralLoader";

export default class ContainerSearch extends React.Component<any, any> {
  state: any = {
    lang: TranslationService.getSelectedLanguage(),
    value: "",
    suggestions: []
  };
  async componentWillMount() {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({ lang: TranslationService.getSelectedLanguage() })
    );
  }
  onChange(event: any, { newValue }: any) {
    this.setState({ value: newValue.toUpperCase() });
  }
  onEnter(event: any) {
    const { stateService } = this.props.transition.router;

    // Only Enter
    if (event.keyCode !== 13) return;

    const containerID = this.state.suggestions[0]
      ? this.state.suggestions[0].ContainerNumber
      : this.state.value;

    stateService.go("containers_single", { containerID });
  }

  renderSuggestion(value: Container) {
    return (
      <UISref
        to="containers_single"
        params={{ containerID: value.ContainerNumber }}
      >
        <a>
          <span>
            {value.ContainerNumber} <em>{value.Description}</em>
          </span>
        </a>
      </UISref>
    );
  }
  onSuggestionsFetchRequested({ value }: any) {
    GeneralLoader.increase();
    ContainerService.containerSearchByNumber(value)
      .then((suggestions: Container[]) => this.setState({ suggestions }))
      .then(() => {
        GeneralLoader.decrease();
      })
      .catch(() => {
        GeneralLoader.decrease();
      });
  }
  onSuggestionsClearRequested = () => this.setState({ suggestions: [] });
  render() {
    const { value, suggestions } = this.state;

    return (
      <div className="container-search col-xs-12">
        <h1 className="container-search-header">
          {languages("MENU_SEARCHCONTAINERS")}
        </h1>
        <p>{languages("TEXT_SEARCHCONTAINERS_INPUT")}:</p>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(
            this
          )}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(
            this
          )}
          shouldRenderSuggestions={(value: string) => value.trim().length >= 2}
          getSuggestionValue={() => this.state.value}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            value,
            onChange: this.onChange.bind(this),
            onKeyDown: this.onEnter.bind(this)
          }}
        />
      </div>
    );
  }
}
