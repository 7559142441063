import * as React from "react";
import {
  ShipmentService,
  languages,
  SamskipNotify,
  UserService,
} from "Services";
import CustomerFormGroup from "./customerFormGroup";
import {
  RequestFormData,
  CreateRequestVM,
  RequestVM,
  FormError,
  UnitFormData,
} from "./interfaces";
import styles from "./index.less";
import * as _ from "lodash";
import {
  Header,
  Grid,
  Form,
  Button,
  DropdownItemProps,
} from "semantic-ui-react";
import { Input, Textarea, Dropdown } from "Component";
import "ts-nameof";
import {
  requiredValidator,
  notEmptyStringValidator,
  emailValidator,
  phoneNumberValidator,
  postcodeOrTerminalCodeValidator,
  validateAll,
  lengthValidatior,
} from "../../shared/validators/validators";
import { DRIVE_DEPARTMENTS } from "Constants/DepartmentConstants";
import { REQUEST_CONTAINER_STATUSES } from "Constants/RequestConstants";
import moment from "moment";
import { StringUtilities } from "Utilities";

declare var GlobalConfig: GlobalConfig;

const drivenFromIdSelectors = {
  ssnName: "driven_from_ssn_name",
  name: "driven_from_name",
  address: "driven_from_address",
  city: "driven_from_city",
  zipcode: "driven_from_zipcode",
};

const drivenToIdSelectors = {
  ssnName: "driven_to_ssn_name",
  name: "driven_to_name",
  address: "driven_to_address",
  city: "driven_to_city",
  zipcode: "driven_to_zipcode",
};

export default class NoEquipmentRequestForm extends React.PureComponent<
  any,
  RequestFormData
> {
  state = {
    from: {
      SSN: "",
      Name: "",
      Address: "",
      City: "",
      Postcode: "",
    } as Customer,
    to: { SSN: "", Name: "", Address: "", City: "", Postcode: "" } as Customer,
    departmentOptions: [] as DropdownItemProps[],
    department: {
      Department: "",
      DepartmentName: "",
      Address1: "",
      Address2: "",
      ZipCode: "",
    } as Department,
    requestNotes: "",
    requestorName: "",
    requestorPhone: "",
    requestorEmail: "",
    jobReference: "",
    previousRequest: null,
    // prettier-ignore
    formErrors: [
      { key: "requestorName", validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: "requestorPhone", validators: [requiredValidator, notEmptyStringValidator, phoneNumberValidator], value: false },
      { key: "requestorEmail", validators: [emailValidator], value: false },
      { key: "department", validators: [requiredValidator], value: false },
      // From
      { key: `${"from"}.${"SSN"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"from"}.${"Name"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"from"}.${"Address"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"from"}.${"City"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"from"}.${"Postcode"}`, validators: [requiredValidator, postcodeOrTerminalCodeValidator], value: false },
      // To
      { key: `${"to"}.${"SSN"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"to"}.${"Name"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"to"}.${"Address"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"to"}.${"City"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"to"}.${"Postcode"}`, validators: [requiredValidator, postcodeOrTerminalCodeValidator], value: false },
    ] as FormError[],
    isSubmitting: false,
  } as RequestFormData;

  componentDidMount = async () => {
    await this.initializeOptions();
    this.initialize(this.props.request);
  };

  initialize = (request: RequestVM) => {
    const { Payer, From, To, Requestor, Units, RequestID } = request;
    const Unit = Units[0];
    let department = this.state.departments.find(
      (item) => item.Department === Payer.Id
    );
    // If the payid is not a department or SOTT use INNL
    if (
      !department &&
      (Object.keys(DRIVE_DEPARTMENTS).indexOf(Payer.Id) === -1 ||
        Payer.Id !== DRIVE_DEPARTMENTS.SOTT)
    ) {
      department = this.state.departments.find((item: Department) => {
        return item.Department === DRIVE_DEPARTMENTS.INNL;
      });
      if (!department) {
        Payer.Id = "";
        Payer.Name = "";
        Payer.Address1 = "";
        Payer.Address2 = "";
        Payer.Place = "";
        Payer.PostCode = "";
      }
    }
    // If the payid is a department that the user does not have access to the payid value is cleared
    else if (
      !department &&
      Object.keys(DRIVE_DEPARTMENTS).indexOf(Payer.Id) !== -1
    ) {
      Payer.Id = "";
      Payer.Name = "";
      Payer.Address1 = "";
      Payer.Address2 = "";
      Payer.Place = "";
      Payer.PostCode = "";
    }
    this.setState({
      jobReference: request.JobReference
        ? StringUtilities.truncate(request.JobReference, 10)
        : "",
      requestorName: Requestor.Name ? Requestor.Name : "",
      requestorPhone: Requestor.Phone ? Requestor.Phone : "",
      requestorEmail: Requestor.Email ? Requestor.Email : "",
      department: department ? department : null,
      to: {
        Name: From.Name ? From.Name : "",
        Address: From.Address1 ? From.Address1 : "",
        SSN: From.Id ? From.Id : "",
        City: From.Address2 ? From.Address2 : "",
        Postcode: From.PostCode ? From.PostCode : "",
        IsPort: "",
      },
      from: {
        Name: To.Name ? To.Name : "",
        Address: To.Address1 ? To.Address1 : "",
        SSN: To.Id ? To.Id : "",
        City: To.Address2 ? To.Address2 : "",
        Postcode: To.PostCode ? To.PostCode : "",
        IsPort: "",
      },
      units: [
        {
          ContainerStatus: REQUEST_CONTAINER_STATUSES.EMPTY,
          Transport: Unit.Transport,
        } as UnitFormData,
      ],
      previousRequest: RequestID ? parseInt(RequestID, 10) : null,
    });
  };

  initializeOptions = async () => {
    const currentUser = UserService.getUserProfile();
    if (currentUser) {
      const departments = await ShipmentService.getUserDepartments(
        currentUser.Access.UserID,
        currentUser.Access.SubSys
      );
      this.handleSetState({
        departments,
        departmentOptions: this.createDepartmentOptions(
          departments,
          "DepartmentName",
          "Department"
        ),
      });
    }
  };

  handleValidation = (key: any) => {
    this.setState((prevState) => {
      const formErrors = prevState.formErrors.map(
        (item: FormError, i: number) => {
          if (i === _.findIndex(prevState.formErrors, ["key", key])) {
            item.value = !validateAll(item.validators, _.get(this.state, key));
          }
          return item;
        }
      );
      return {
        ...prevState,
        formErrors,
      };
    });
  };

  // Disables error handling for input field that has focus
  onDisableError = (name: any) => {
    this.setState((prevState) => {
      const formErrors = prevState.formErrors.map(
        (item: FormError, i: number) => {
          if (i === _.findIndex(prevState.formErrors, ["key", name])) {
            item.value = false;
          }
          return item;
        }
      );
      return {
        ...prevState,
        formErrors,
      };
    });
  };

  validateForm = () => {
    const formErrors = _.cloneDeep(this.state.formErrors);
    if (this.state.requestorEmail === "") {
      _.remove(
        formErrors,
        (item) =>
          item.key === "requestorEmail"
      );
    }

    for (const formError of formErrors) {
      formError.value = !validateAll(
        formError.validators,
        _.get(this.state, formError.key)
      );
    }

    let result = true;
    this.handleSetState({
      formErrors: _.map(this.state.formErrors, (item) => {
        const formError = _.find(formErrors, (fe) => fe.key === item.key);
        if (formError) {
          item.value = formError.value;
          result = formError.value === true ? false : result;
        }
        return item;
      }),
    });
    return result;
  };

  hasError = (propName: string) => {
    const formError = this.state.formErrors.find(
      (item) => item.key === propName
    );
    if (formError) {
      return formError.value;
    }
    return false;
  };

  // A specific function to create department options for translating labels
  createDepartmentOptions = (
    list: any[],
    textName: string,
    valueName: string
  ) => {
    return list.map((item: any, i: number) => {
      return {
        key: i,
        text: languages(item[textName]),
        value: item[valueName],
      } as DropdownItemProps;
    });
  };

  onDepartmentSelected = (value: string) => {
    const d = _.find(this.state.departments, ["Department", value]);
    this.handleSetState({
      department: d,
    });
  };

  handleSetState = (object: any, callback?: Function) => {
    this.setState(
      (prevState) => ({ ...prevState, ...object }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  onBlur = (propName: string) => {
    this.handleSetState({ [propName]: this.state[propName].trim() }, () =>
      this.handleValidation(propName)
    );
  };

  onSubmit = () => {
    if (!this.validateForm()) {
      SamskipNotify.displayWarning(
        languages("ERROR_INVALID_FORM_INPUT"),
        languages("LABEL_ERROR")
      );
      return;
    }

    const formData = this.state;
    const requestVM: CreateRequestVM = {
      RequestType:
        formData.department &&
        formData.department.Department === DRIVE_DEPARTMENTS.SOTT
          ? 2
          : 1,
      FromId: formData.from.SSN,
      FromName: formData.from.Name,
      FromAddress1: formData.from.Address,
      FromAddress2: formData.from.City,
      FromPlace: formData.from.Postcode,
      FromZip: formData.from.Postcode,
      ToId: formData.to.SSN,
      ToName: formData.to.Name,
      ToAddress1: formData.to.Address,
      ToAddress2: formData.to.City,
      ToPlace: formData.to.Postcode,
      ToZip: formData.to.Postcode,
      PayId: formData.department ? formData.department.Department : "",
      PayName: formData.department
        ? languages(formData.department.DepartmentName)
        : "",
      PayAddress1: formData.department ? formData.department.Address1 : "",
      PayPlace: formData.department ? formData.department.Place : "",
      PayZip: formData.department ? formData.department.ZipCode : "",
      RequestNotes: formData.requestNotes,
      RequestorName: formData.requestorName,
      RequestorEmail:
        formData.requestorEmail === "" ? null : formData.requestorEmail,
      RequestorPhone: formData.requestorPhone,
      Haulier:
        formData.department &&
        formData.department.Department === DRIVE_DEPARTMENTS.SOTT
          ? 1
          : 0,
      JobReference: formData.jobReference,
      ConnectedRequestReference: formData.previousRequest,
    } as CreateRequestVM;
    const units = [
      {
        ...formData.units[0],
        ContainerType: 9999,
        DateRequested: moment().toDate(),
      },
    ];
    this.setState({ isSubmitting: true });
    ShipmentService.createRequests(requestVM, units)
      .then((data: string[]) => {
        const message = (
          <ul className="u-noPadding u-margin0">
            {data.map((item) => (
              <li className="u-noListStyle">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${GlobalConfig.ENV.OldServiceWeb}/PDFDisplay.aspx?Req=${item}`}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        );
        const options = {
          autoClose: false as const,
          closeButton: true,
          closeOnClick: false,
        };
        SamskipNotify.displaySuccess(
          message,
          languages("BOOKINGHISTORYTYPE_REQCREATE", data.length),
          undefined,
          options
        );
      })
      .catch((err: any) => {
        SamskipNotify.displayError(languages("ERROR_INTERNAL_SERVER_ERROR"));
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
        this.props.onSubmit();
      });
  };

  render() {
    const { from, to, department, departmentOptions, isSubmitting } =
      this.state;

    return (
      <Form className={`${styles.form} ${this.props.className}`}>
        <Grid celled stackable className={styles.borderBoxFrame}>
          <Grid.Row centered columns={1}>
            <Grid.Column width={16}>
              <Header as="h3">
                <label className={styles.boxTitle}>
                  {languages("LABEL_BOOKINGREFERENCE")}
                </label>
              </Header>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "jobReference"
                    ]
                  }
                  type="text"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["jobReference"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_BOOKINGREFERENCE")}
                  id="bookingReference"
                  maxLength={10}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Header as="h3">
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_PAYER")}
                </label>
              </Header>
              <Dropdown
                placeholder={languages("LABEL_CHOOSEPAYER")}
                options={departmentOptions}
                value={department ? department.Department : ""}
                onChange={(value: any) => {
                  this.onDepartmentSelected(value);
                }}
                selectOnNavigation={false}
                onBlur={() => {
                  this.handleValidation(
                    "department"
                  );
                }}
                onFocus={() => {
                  this.onDisableError(
                    "department"
                  );
                }}
                error={this.hasError(
                  "department"
                )}
                search
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3">
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_REQUESTOR")}
                </label>
              </Header>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "requestorName"
                    ]
                  }
                  type="text"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["requestorName"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_NAME")}
                  onBlur={() =>
                    this.onBlur(
                      "requestorName"
                    )
                  }
                  onFocus={() =>
                    this.onDisableError(
                      "requestorName"
                    )
                  }
                  error={this.hasError(
                    "requestorName"
                  )}
                  id="requestor_name"
                  name="requestor_name"
                  autoComplete="requestor_name"
                />
              </Form.Field>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "requestorPhone"
                    ]
                  }
                  type="tel"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["requestorPhone"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_PHONENUMBER")}
                  onBlur={() =>
                    this.handleValidation(
                      "requestorPhone"
                    )
                  }
                  onFocus={() =>
                    this.onDisableError(
                      "requestorPhone"
                    )
                  }
                  error={this.hasError(
                    "requestorPhone"
                  )}
                  id="requestor_phonenumber"
                  name="requestor_phonenumber"
                  autoComplete="requestor_phonenumber"
                />
              </Form.Field>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "requestorEmail"
                    ]
                  }
                  type="email"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["requestorEmail"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_EMAIL")}
                  onBlur={() => {
                    if (
                      this.state[
                        "requestorEmail"
                      ] !== ""
                    ) {
                      this.onBlur(
                        "requestorEmail"
                      );
                    }
                  }}
                  onFocus={() =>
                    this.onDisableError(
                      "requestorEmail"
                    )
                  }
                  error={this.hasError(
                    "requestorEmail"
                  )}
                  id="requestor_email"
                  name="requestor_email"
                  autoComplete="requestor_email"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Header>
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_DRIVEN_FROM")}
                </label>
              </Header>
              <CustomerFormGroup
                customer={from}
                onChange={(customer: Customer) => {
                  this.handleSetState({
                    ["from"]: customer,
                  });
                }}
                onHandleValidation={(propName: string) => {
                  this.handleValidation(
                    `${"from"}.${propName}`
                  );
                }}
                onDisableError={(propName: string) => {
                  this.onDisableError(
                    `${"from"}.${propName}`
                  );
                }}
                hasError={(propName: string) => {
                  return this.hasError(
                    `${"from"}.${propName}`
                  );
                }}
                idSelectors={drivenFromIdSelectors}
              />
            </Grid.Column>
            <Grid.Column widtd={8}>
              <Header>
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_DRIVEN_TO")}
                </label>
              </Header>
              <CustomerFormGroup
                customer={to}
                onChange={(customer: Customer) => {
                  this.handleSetState({
                    ["to"]: customer,
                  });
                }}
                onHandleValidation={(propName: string) => {
                  this.handleValidation(
                    `${"to"}.${propName}`
                  );
                }}
                onDisableError={(propName: string) => {
                  this.onDisableError(
                    `${"to"}.${propName}`
                  );
                }}
                hasError={(propName: string) => {
                  return this.hasError(
                    `${"to"}.${propName}`
                  );
                }}
                idSelectors={drivenToIdSelectors}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Header>
                <label className={styles.boxTitle}>
                  {languages("LABEL_COMMENTS")}
                </label>
              </Header>
              <Textarea
                value={
                  this.state[
                    "requestNotes"
                  ]
                }
                placeholder={languages("TEXT_WRITE_COMMENTS")}
                onChange={(value: any) => {
                  this.handleSetState({
                    ["requestNotes"]:
                      value,
                  });
                }}
                maxLength={230}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Form.Field
                control={Button}
                onClick={this.onSubmit}
                primary
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {languages("LABEL_SUBMIT")}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}
