import { VALIDATION_REGEX } from "Constants/ValidationConstants";

import { ShipmentRegistryService } from "Services";
import moment from "moment-timezone";

const nonEmptyorWhitespaceRegex = RegExp(
  VALIDATION_REGEX.nonEmptyorWhitespaceRegex
);
const validEmailRegex = RegExp(VALIDATION_REGEX.emailRegex);
const postcodeOrTerminalCodeRegex = RegExp(
  VALIDATION_REGEX.postcodeOrTerminalCodeRegex
);
const phoneNumberRegex = RegExp(VALIDATION_REGEX.phoneNumberRegex);

const requiredValidator = (value: any) => {
  return !(value === null || value === undefined);
};

const notEmptyStringValidator = (value: string) => {
  return nonEmptyorWhitespaceRegex.test(value);
};

const emailValidator = (value: string) => {
  if (value) {
    return validEmailRegex.test(value);
  }
  return true;
};

const multipleEmailValidator = (value: string) => {
  if (value) {
    const emails = value.split(/;/);
    return emails.every((email) => {
      return validEmailRegex.test(email.trim());
    });
  }
  return true;
};

const postcodeOrTerminalCodeValidator = (value: string) => {
  return postcodeOrTerminalCodeRegex.test(value);
};

const futureDateValidator = (date: Date) => {
  return date > new Date();
};

const phoneNumberValidator = (value: string) => {
  if (value) {
    return phoneNumberRegex.test(value);
  }
  return true;
};

const futureDateAndNotNextFourHoursValidator = (date: Date) => {
  return date > moment(new Date()).add(4, "hours").toDate();
};

/**
 * Creates a length validator, takes the desired length as a parameter.
 * @param length The minimum length the given value is required to match.
 */
const lengthValidatior = (length: number) => {
  return (value: string) => {
    return value.length >= length;
  };
};

const validateAll = (validators: Function[], value: any) => {
  for (const validator of validators) {
    if (!validator(value)) {
      return false;
    }
  }
  return true;
};

export {
  requiredValidator,
  notEmptyStringValidator,
  emailValidator,
  multipleEmailValidator,
  postcodeOrTerminalCodeValidator,
  futureDateValidator,
  phoneNumberValidator,
  lengthValidatior,
  futureDateAndNotNextFourHoursValidator,
  validateAll,
};
