import React from "react";
import {
  UserService,
  ShipmentService,
  languages,
  CompanyService,
  SamskipNotify,
  RequestService,
} from "Services";
import CustomerFormGroup from "./customerFormGroup";
import {
  RequestFormData,
  UnitFormData,
  CreateRequestVM,
  FormError,
  Commodity,
  ContainerType,
  DriveRequestProps,
  RequestVM,
} from "./interfaces";
import styles from "./index.less";
import * as _ from "lodash";
import {
  Header,
  Grid,
  Form,
  Button,
  Radio,
  Icon,
  Table,
  DropdownItemProps,
  Checkbox,
} from "semantic-ui-react";
import { Input, Textarea, Dropdown } from "Component";
import ContainerTableRow from "./containerTableRow";
import "ts-nameof";
import {
  requiredValidator,
  notEmptyStringValidator,
  emailValidator,
  phoneNumberValidator,
  postcodeOrTerminalCodeValidator,
  futureDateValidator,
  validateAll,
} from "../../shared/validators/validators";
import { DateUtilities } from "Utilities";
import queryString from "query-string";
import { DRIVE_DEPARTMENTS } from "Constants/DepartmentConstants";
import { REQUEST_CONTAINER_STATUSES } from "Constants/RequestConstants";

declare var GlobalConfig: GlobalConfig;

// Samskip hf SSN to set initial driven from place
const samSSN = "4409861539";
const timeInterval = 30;

const payerIdSelectors = {
  ssnName: "payer_ssn_name",
  name: "payer_name",
  address: "payer_address",
  city: "payer_city",
  zipcode: "payer_zipcode",
};

const drivenFromIdSelectors = {
  ssnName: "driven_from_ssn_name",
  name: "driven_from_name",
  address: "driven_from_address",
  city: "driven_from_city",
  zipcode: "driven_from_zipcode",
};

const drivenToIdSelectors = {
  ssnName: "driven_to_ssn_name",
  name: "driven_to_name",
  address: "driven_to_address",
  city: "driven_to_city",
  zipcode: "driven_to_zipcode",
};

const initialstate = {
  payer: { SSN: "", Name: "", Address: "", City: "", Postcode: "" } as Customer,
  from: { SSN: "", Name: "", Address: "", City: "", Postcode: "" } as Customer,
  to: { SSN: "", Name: "", Address: "", City: "", Postcode: "" } as Customer,
  units: [] as UnitFormData[],
  departmentOptions: [] as DropdownItemProps[],
  commodities: [] as Commodity[],
  containerTypes: [] as ContainerType[],
  interCompanyChecked: false,
  department: null,
  requestNotes: "",
  requestorName: "",
  requestorPhone: "",
  requestorEmail: "",
  jobReference: "",
  previousRequest: null,
  transport: "G",
  doorPosition: "",
  containerStatus: REQUEST_CONTAINER_STATUSES.EMPTY,
  // prettier-ignore
  formErrors: [
    { key: "requestorName", validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: "requestorPhone", validators: [requiredValidator, notEmptyStringValidator, phoneNumberValidator], value: false },
    { key: "requestorEmail", validators: [emailValidator], value: false },
    { key: "department", validators: [requiredValidator], value: false },
    { key: "transport", validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: "doorPosition", validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: "containerStatus", validators: [requiredValidator, notEmptyStringValidator], value: false },
    // Payer
    { key: `${"payer"}.${"SSN"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"payer"}.${"Name"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"payer"}.${"Address"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"payer"}.${"City"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"payer"}.${"Postcode"}`, validators: [requiredValidator, postcodeOrTerminalCodeValidator], value: false },
    // From
    { key: `${"from"}.${"SSN"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"from"}.${"Name"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"from"}.${"Address"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"from"}.${"City"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"from"}.${"Postcode"}`, validators: [requiredValidator, postcodeOrTerminalCodeValidator], value: false },
    // To
    { key: `${"to"}.${"SSN"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"to"}.${"Name"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"to"}.${"Address"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"to"}.${"City"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"to"}.${"Postcode"}`, validators: [requiredValidator, postcodeOrTerminalCodeValidator], value: false },
    // Units
    { key: `${"units"}[0].${"Container"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"units"}[0].${"Cargo"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"units"}[0].${"ContainerType"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"units"}[0].${"Weight"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"units"}[0].${"Temperature"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
    { key: `${"units"}[0].${"DateRequested"}`, validators: [requiredValidator, futureDateValidator], value: false }
  ] as FormError[],
  isSubmitting: false,
} as RequestFormData;

export default class DriveRequestForm extends React.PureComponent<
  DriveRequestProps,
  RequestFormData
> {
  state = initialstate;

  componentDidMount = async () => {
    this.initializeOptions();
    if (this.props.previousRequest) {
      this.initializeWithPreviousRequest(this.props.previousRequest);
    } else if (this.props.container) {
      this.initializeWithContainer(this.props.container);
    } else {
      this.initialize();
    }
  };

  initialize = () => {
    if (this.props.selectedCompany) {
      initialstate.payer = _.cloneDeep(this.props.selectedCompany);
    }
    initialstate.from = _.cloneDeep(this.props.samskipPartner);
    this.setState({
      ..._.cloneDeep(initialstate),
      units: [
        {
          Container: "",
          DateRequested: DateUtilities.getNextIntervalFromDate(
            DateUtilities.getCurrentDate(),
            timeInterval
          ),
        } as UnitFormData,
      ],
    });
  };

  initializeWithContainer = (container: Container) => {
    if (this.props.selectedCompany) {
      initialstate.payer = _.cloneDeep(this.props.selectedCompany);
    }
    initialstate.to = _.cloneDeep(this.props.samskipPartner);
    this.setState({
      ..._.cloneDeep(initialstate),
      units: [
        {
          Container: container.ContainerNumber,
          ContainerType: container.ISOCode,
          DateRequested: DateUtilities.getNextIntervalFromDate(
            DateUtilities.getCurrentDate(),
            timeInterval
          ),
        } as UnitFormData,
      ],
    });
  };

  initializeWithPreviousRequest = (prevRequest: RequestVM) => {
    const department = this.state.departments.find(
      (item) => item.Department === prevRequest.Payer.Id
    );
    const { Payer, Requestor, From, To } = prevRequest;
    const Unit = prevRequest.Units[0];
    this.setState({
      ..._.cloneDeep(initialstate),
      requestorName: Requestor.Name ? Requestor.Name : "",
      requestorPhone: Requestor.Phone ? Requestor.Phone : "",
      requestorEmail: Requestor.Email ? Requestor.Email : "",
      containerStatus: Unit.Status
        ? this.reverseContainerStatus(Unit.Status)
        : "",
      doorPosition: Unit.Door ? Unit.Door : "",
      interCompanyChecked: department ? true : false,
      department: department ? department : null,
      transport: Unit.Transport,
      payer: department
        ? ({} as Customer)
        : {
            Name: Payer.Name ? Payer.Name : "",
            Address: Payer.Address1 ? Payer.Address1 : "",
            SSN: Payer.Id ? Payer.Id : "",
            City: Payer.Address2 ? Payer.Address2 : "",
            Postcode: Payer.PostCode ? Payer.PostCode : "",
            IsPort: "",
          },
      to: {
        Name: From.Name ? From.Name : "",
        Address: From.Address1 ? From.Address1 : "",
        SSN: From.Id ? From.Id : "",
        City: From.Address2 ? From.Address2 : "",
        Postcode: From.PostCode ? From.PostCode : "",
        IsPort: "",
      },
      from: {
        Name: To.Name ? To.Name : "",
        Address: To.Address1 ? To.Address1 : "",
        SSN: To.Id ? To.Id : "",
        City: To.Address2 ? To.Address2 : "",
        Postcode: To.PostCode ? To.PostCode : "",
        IsPort: "",
      },
      units: [
        {
          DateRequested: DateUtilities.getNextIntervalFromDate(
            DateUtilities.getCurrentDate(),
            timeInterval
          ),
          Container: Unit.Number ? Unit.Number : "",
          ContainerType: Unit.Type ? Unit.Type : "",
          Cargo: Unit.Cargo ? Unit.Cargo : "",
          Temperature: Unit.Temperature ? Unit.Temperature : "",
        } as UnitFormData,
      ],
      previousRequest: prevRequest.RequestID
        ? parseInt(prevRequest.RequestID, 10)
        : null,
    });
  };

  initializeOptions = () => {
    initialstate.commodities = _.cloneDeep(this.props.commodities);
    initialstate.containerTypes = _.cloneDeep(this.props.containerTypes);
    initialstate.departments = _.cloneDeep(this.props.departments);
    initialstate.departmentOptions = this.createDepartmentOptions(
      this.props.departments,
      "DepartmentName",
      "Department"
    );
  };

  handleValidation = (key: any) => {
    this.setState((prevState) => {
      const formErrors = prevState.formErrors.map(
        (item: FormError, i: number) => {
          if (i === _.findIndex(prevState.formErrors, ["key", key])) {
            item.value = !validateAll(item.validators, _.get(this.state, key));
          }
          return item;
        }
      );
      return {
        ...prevState,
        formErrors,
      };
    });
  };

  handleUnitValidation = (key: string) => {
    if (
      this.state.containerStatus === REQUEST_CONTAINER_STATUSES.EMPTY &&
      key.endsWith("Container")
    ) {
      return;
    }
    if (key.endsWith("Cargo")) {
      const type = this.state.containerTypes.find((item) => {
        return (
          item.ISOType === _.get(this.state, key.split(".")[0]).ContainerType
        );
      });
      if (
        (type &&
          ((type.Refrigerated && type.Refrigerated === "N") ||
            !type.Refrigerated)) ||
        !type
      ) {
        return;
      }
    }
    if (key.endsWith("Temperature")) {
      const commodity = this.state.commodities.find((item) => {
        return (
          item.CommodityCode === _.get(this.state, key.split(".")[0]).Cargo
        );
      });
      if ((commodity && commodity.LowerTemperature === null) || !commodity) {
        return;
      }
    }
    this.handleValidation(key);
  };

  // Disables error handling for input field that has focus
  onDisableError = (name: any) => {
    this.setState((prevState) => {
      const formErrors = prevState.formErrors.map(
        (item: FormError, i: number) => {
          if (i === _.findIndex(prevState.formErrors, ["key", name])) {
            item.value = false;
          }
          return item;
        }
      );
      return {
        ...prevState,
        formErrors,
      };
    });
  };

  onToggleInterCompany = () => {
    this.onDisableError("department");
    for (const key of _.keys(this.state.payer)) {
      this.onDisableError(
        `${"payer"}.${key}`
      );
    }
    this.handleSetState({
      interCompanyChecked: !this.state.interCompanyChecked,
      department: null,
      payer: _.mapValues(this.state.payer, () => ""),
    });
  };

  validateForm = () => {
    const formErrors = _.cloneDeep(this.state.formErrors);
    if (this.state.interCompanyChecked) {
      _.remove(formErrors, (item) =>
        item.key.startsWith("payer")
      );
    } else {
      _.remove(
        formErrors,
        (item) =>
          item.key === "department"
      );
    }

    if (this.state.transport !== "G") {
      _.remove(
        formErrors,
        (item) =>
          item.key === "doorPosition"
      );
    }

    if (this.state.requestorEmail === "") {
      _.remove(
        formErrors,
        (item) =>
          item.key === "requestorEmail"
      );
    }

    if (this.state.containerStatus === REQUEST_CONTAINER_STATUSES.EMPTY) {
      _.remove(
        formErrors,
        (item) =>
          item.key.endsWith("Container") ||
          item.key.endsWith("Weight")
      );
    }

    const units = this.state.units;
    for (let i = 0; i < units.length; i = i + 1) {
      const commodity = this.state.commodities.find(
        (item) => item.CommodityCode === units[i].Cargo
      );
      const containerType = this.state.containerTypes.find(
        (item) => item.ISOType === units[i].ContainerType
      );
      if ((commodity && commodity.LowerTemperature === null) || !commodity) {
        _.remove(formErrors, (item) => {
          return (
            item.key ===
            `${"units"}[${i}].` +
              `${"Temperature"}`
          );
        });
      }
      // Cargo is not required unless the containerType selected is refrigerated
      if (
        (containerType &&
          containerType.Refrigerated &&
          containerType.Refrigerated === "N") ||
        (containerType && !containerType.Refrigerated) ||
        !containerType
      ) {
        _.remove(formErrors, (item) => {
          return (
            item.key ===
            `${"units"}[${i}].` +
              `${"Cargo"}`
          );
        });
      }
    }

    for (const formError of formErrors) {
      formError.value = !validateAll(
        formError.validators,
        _.get(this.state, formError.key)
      );
    }

    let result = true;
    this.handleSetState({
      formErrors: _.map(this.state.formErrors, (item) => {
        const formError = _.find(formErrors, (fe) => fe.key === item.key);
        if (formError) {
          item.value = formError.value;
          result = formError.value === true ? false : result;
        }
        return item;
      }),
    });
    return result;
  };

  hasError = (propName: string) => {
    const formError = this.state.formErrors.find(
      (item) => item.key === propName
    );
    if (formError) {
      return formError.value;
    }
    return false;
  };

  // A specific function to create department options for translating labels
  createDepartmentOptions = (
    list: any[],
    textName: string,
    valueName: string
  ) => {
    return list.map((item: any, i: number) => {
      return {
        key: i,
        text: languages(item[textName]),
        value: item[valueName],
      } as DropdownItemProps;
    });
  };

  onContainerSelected = async (container: Container) => {
    if (container.LocationType === "T") {
      CompanyService.searchCustomers(samSSN).then((values: Customer[]) => {
        this.handleSetState({
          from: this.props.samskipPartner,
        });
      });
    } else if (container.Location === "STREETPOOLIS") {
      ShipmentService.getLatestRequestIDForContainer(
        container.ContainerNumber,
        UserService.getSelectedCompany().PartnerCode
      )
        .then((value: any) => {
          return RequestService.getRequestByRequestID(
            value.RequestId as string
          );
        })
        .then((prevRequest: RequestVM) => {
          this.initializeWithPreviousRequest(prevRequest);
        })
        .catch((err: any) => {
          if (err.status !== 404) {
            throw err;
          } else {
            this.handleSetState({
              to: this.props.samskipPartner,
            });
          }
        });
    }
  };

  onDepartmentSelected = (value: string) => {
    const d = _.find(this.state.departments, ["Department", value]);
    this.handleSetState({
      department: d,
    });
  };

  handleSetState = (object: any, callback?: Function) => {
    this.setState(
      (prevState) => ({ ...prevState, ...object }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  onBlur = (propName: string) => {
    this.handleSetState({ [propName]: this.state[propName].trim() }, () =>
      this.handleValidation(propName)
    );
  };

  onRemoveUnitRow = (e: any, index: number) => {
    e.preventDefault();
    const units = this.state.units.filter((item: UnitFormData, i: number) => {
      return i !== index;
    });
    const formErrors = this.state.formErrors.filter((item: FormError) => {
      return !item.key.startsWith(
        `${"units"}[${index}]`
      );
    });
    this.setState({ units, formErrors });
  };

  onAddUnitRow = (e: any) => {
    e.preventDefault();
    const newUnit = _.clone(this.state.units[this.state.units.length - 1]);
    newUnit.Container = "";
    const units = [...this.state.units, newUnit];
    // prettier-ignore
    const formErrors = [
      ...this.state.formErrors,
      { key: `${"units"}[${ this.state.units.length }].${"Container"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"units"}[${ this.state.units.length }].${"Cargo"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"units"}[${ this.state.units.length }].${"ContainerType"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"units"}[${ this.state.units.length }].${"Weight"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"units"}[${ this.state.units.length }].${"Temperature"}`, validators: [requiredValidator, notEmptyStringValidator], value: false },
      { key: `${"units"}[${ this.state.units.length }].${"DateRequested"}`, validators: [requiredValidator, futureDateValidator], value: false }
    ];
    this.setState({ units, formErrors });
  };

  removeExcessUnitRows = () => {
    if (this.state.units.length > 1) {
      const formErrors = _.filter(this.state.formErrors, (item) => {
        return !(
          item.key.startsWith("units") &&
          !item.key.startsWith(
            `${"units"}[0]`
          )
        );
      });
      const units = [this.state.units[0]];
      this.handleSetState({ formErrors, units });
    }
  };

  resetUnitErrors = () => {
    const formErrors = _.map(this.state.formErrors, (item) => {
      if (item.key.startsWith("units")) {
        item.value = false;
      }
      return item;
    });
    this.handleSetState({ formErrors });
  };

  onSubmit = () => {
    if (!this.validateForm()) {
      SamskipNotify.displayWarning(
        languages("ERROR_INVALID_FORM_INPUT"),
        languages("LABEL_ERROR")
      );
      return;
    }

    const formData = this.state;
    const requestVM: CreateRequestVM = {
      RequestType:
        formData.department &&
        formData.department.Department === DRIVE_DEPARTMENTS.SOTT
          ? 2
          : 1,
      FromId: formData.from.SSN,
      FromName: formData.from.Name,
      FromAddress1: formData.from.Address,
      FromAddress2: formData.from.City,
      FromPlace: formData.from.Postcode,
      FromZip: formData.from.Postcode,
      ToId: formData.to.SSN,
      ToName: formData.to.Name,
      ToAddress1: formData.to.Address,
      ToAddress2: formData.to.City,
      ToPlace: formData.to.Postcode,
      ToZip: formData.to.Postcode,
      PayId:
        formData.interCompanyChecked && formData.department
          ? formData.department.Department
          : formData.payer.SSN,
      PayName:
        formData.interCompanyChecked && formData.department
          ? languages(formData.department.DepartmentName)
          : formData.payer.Name,
      PayAddress1:
        formData.interCompanyChecked && formData.department
          ? formData.department.Address1
          : formData.payer.Address,
      PayPlace:
        formData.interCompanyChecked && formData.department
          ? formData.department.Place
          : formData.payer.City,
      PayZip:
        formData.interCompanyChecked && formData.department
          ? formData.department.ZipCode
          : formData.payer.Postcode,
      RequestNotes: formData.requestNotes,
      RequestorName: formData.requestorName,
      RequestorEmail:
        formData.requestorEmail === "" ? null : formData.requestorEmail,
      RequestorPhone: formData.requestorPhone.trim(),
      Haulier:
        formData.department &&
        formData.department.Department === DRIVE_DEPARTMENTS.SOTT
          ? 1
          : 0,
      JobReference: formData.jobReference,
      ConnectedRequestReference: formData.previousRequest,
    } as CreateRequestVM;

    const units = formData.units.map((item) => {
      item.Transport = formData.transport;
      item.Door = formData.doorPosition;
      item.ContainerStatus = formData.containerStatus;
      return item;
    });
    this.setState({ isSubmitting: true });
    ShipmentService.createRequests(requestVM, units)
      .then((data: string[]) => {
        this.initialize();
        const message = (
          <ul className="u-noPadding u-margin0">
            {data.map((item) => (
              <li className="u-noListStyle">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${GlobalConfig.ENV.OldServiceWeb}/PDFDisplay.aspx?Req=${item}`}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        );
        const options = {
          autoClose: false as const,
          closeButton: true,
          closeOnClick: false,
        };
        SamskipNotify.displaySuccess(
          message,
          languages("BOOKINGHISTORYTYPE_REQCREATE", data.length),
          undefined,
          options
        );
      })
      .catch((err: any) => {
        SamskipNotify.displayError(languages("ERROR_INTERNAL_SERVER_ERROR"));
      })
      .finally(() => {
        this.setState({ isSubmitting: false });
      });
  };

  reverseContainerStatus = (status: string) => {
    if (status === REQUEST_CONTAINER_STATUSES.FULL) {
      return REQUEST_CONTAINER_STATUSES.EMPTY;
    }
    return REQUEST_CONTAINER_STATUSES.FULL;
  };

  render() {
    const {
      payer,
      from,
      to,
      units,
      department,
      departmentOptions,
      interCompanyChecked,
      transport,
      doorPosition,
      containerStatus,
      containerTypes,
      commodities,
      isSubmitting,
    } = this.state;

    return (
      <Form className={styles.form}>
        <Grid celled stackable className={styles.borderBoxFrame}>
          <Grid.Row centered columns={1}>
            <Grid.Column width={16}>
              <Header as="h3">
                <label className={styles.boxTitle}>
                  {languages("LABEL_BOOKINGREFERENCE")}
                </label>
              </Header>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "jobReference"
                    ]
                  }
                  type="text"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["jobReference"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_BOOKINGREFERENCE")}
                  id="bookingReference"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Header as="h3">
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_PAYER")}
                </label>
                <Form.Field
                  className={styles.interCompanyCheck}
                  id="interCompany"
                  control={Checkbox}
                  label={`${languages("LABEL_INTERCOMPANY")}`}
                  checked={interCompanyChecked}
                  onChange={() => this.onToggleInterCompany()}
                  autoFocus
                />
              </Header>
              {interCompanyChecked ? (
                <Dropdown
                  placeholder={languages("LABEL_CHOOSEPAYER")}
                  options={departmentOptions}
                  value={department ? department.Department : ""}
                  onChange={(value: any) => {
                    this.onDepartmentSelected(value);
                  }}
                  selectOnNavigation={false}
                  onBlur={() => {
                    this.handleValidation(
                      "department"
                    );
                  }}
                  onFocus={() => {
                    this.onDisableError(
                      "department"
                    );
                  }}
                  error={this.hasError(
                    "department"
                  )}
                  search
                />
              ) : (
                <CustomerFormGroup
                  customer={payer}
                  onChange={(customer: Customer) => {
                    this.handleSetState({
                      ["payer"]: customer,
                    });
                  }}
                  onHandleValidation={(propName: string) => {
                    this.handleValidation(
                      `${"payer"}.${propName}`
                    );
                  }}
                  onDisableError={(propName: string) => {
                    this.onDisableError(
                      `${"payer"}.${propName}`
                    );
                  }}
                  hasError={(propName: string) => {
                    return this.hasError(
                      `${"payer"}.${propName}`
                    );
                  }}
                  idSelectors={payerIdSelectors}
                />
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h3">
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_REQUESTOR")}
                </label>
              </Header>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "requestorName"
                    ]
                  }
                  type="text"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["requestorName"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_NAME")}
                  onBlur={() =>
                    this.onBlur(
                      "requestorName"
                    )
                  }
                  onFocus={() =>
                    this.onDisableError(
                      "requestorName"
                    )
                  }
                  error={this.hasError(
                    "requestorName"
                  )}
                  id="requestor_name"
                  name="requestor_name"
                  autoComplete="requestor_name"
                />
              </Form.Field>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "requestorPhone"
                    ]
                  }
                  type="tel"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["requestorPhone"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_PHONENUMBER")}
                  onBlur={() =>
                    this.handleValidation(
                      "requestorPhone"
                    )
                  }
                  onFocus={() =>
                    this.onDisableError(
                      "requestorPhone"
                    )
                  }
                  error={this.hasError(
                    "requestorPhone"
                  )}
                  id="requestor_phonenumber"
                  name="requestor_phonenumber"
                  autoComplete="requestor_phonenumber"
                />
              </Form.Field>
              <Form.Field>
                <Input
                  value={
                    this.state[
                      "requestorEmail"
                    ]
                  }
                  type="email"
                  onChange={(value: any) => {
                    this.handleSetState({
                      ["requestorEmail"]:
                        value,
                    });
                  }}
                  placeholder={languages("LABEL_EMAIL")}
                  onBlur={() => {
                    if (
                      this.state[
                        "requestorEmail"
                      ] !== ""
                    ) {
                      this.onBlur(
                        "requestorEmail"
                      );
                    }
                  }}
                  onFocus={() =>
                    this.onDisableError(
                      "requestorEmail"
                    )
                  }
                  error={this.hasError(
                    "requestorEmail"
                  )}
                  id="requestor_email"
                  name="requestor_email"
                  autoComplete="requestor_email"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={8}>
                    <Header>
                      <label
                        className={`${styles.boxTitle} ${styles.required}`}
                      >
                        {languages("LABEL_SHIPPINGTYPE")}
                      </label>
                    </Header>
                    <Form.Group>
                      <Form.Field
                        name="transport"
                        control={Radio}
                        label={languages("LABEL_SIDE_LOADER")}
                        value="G"
                        checked={transport === "G"}
                        onChange={(e: any, data: any) => {
                          this.onDisableError(
                            "doorPosition"
                          );
                          this.handleSetState({
                            ["transport"]:
                              data.value,
                            ["doorPosition"]: "",
                          });
                        }}
                        error={this.hasError(
                          "transport"
                        )}
                      />
                      <Form.Field
                        name="transport"
                        control={Radio}
                        label={languages("LABEL_CHASSIS")}
                        value="D"
                        checked={transport === "D"}
                        onChange={(e: any, data: any) => {
                          this.onDisableError(
                            "doorPosition"
                          );
                          this.handleSetState({
                            ["transport"]:
                              data.value,
                            ["doorPosition"]: "",
                          });
                        }}
                        error={this.hasError(
                          "transport"
                        )}
                      />
                    </Form.Group>
                  </Grid.Column>
                  {transport === "G" ? (
                    <Grid.Column width={8}>
                      <Header>
                        <label
                          className={`${styles.boxTitle} ${styles.required}`}
                        >
                          {languages("LABEL_DOOR_POSITION")}
                        </label>
                      </Header>
                      <Form.Group>
                        <Form.Field
                          name="doorPosition"
                          control={Radio}
                          label={languages("LABEL_FRONT")}
                          value="F"
                          checked={doorPosition === "F"}
                          onChange={(e: any, data: any) => {
                            this.onDisableError(
                              "doorPosition"
                            );
                            this.handleSetState({
                              ["doorPosition"]: data.value,
                            });
                          }}
                          error={this.hasError(
                            "doorPosition"
                          )}
                        />
                        <Form.Field
                          name="doorPosition"
                          control={Radio}
                          label={languages("LABEL_BACK")}
                          value="B"
                          checked={doorPosition === "B"}
                          onChange={(e: any, data: any) => {
                            this.onDisableError(
                              "doorPosition"
                            );
                            this.handleSetState({
                              ["doorPosition"]: data.value,
                            });
                          }}
                          error={this.hasError(
                            "doorPosition"
                          )}
                        />
                      </Form.Group>
                    </Grid.Column>
                  ) : null}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header>
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_FULL_EMPTY")}
                </label>
              </Header>
              <Form.Group>
                <Form.Field
                  name="containerStatus"
                  control={Radio}
                  label={languages("LABEL_FULL_CONTAINER")}
                  value={REQUEST_CONTAINER_STATUSES.FULL}
                  checked={containerStatus === REQUEST_CONTAINER_STATUSES.FULL}
                  onChange={(e: any, data: any) => {
                    this.onDisableError(
                      "containerStatus"
                    );
                    this.handleSetState({
                      ["containerStatus"]:
                        data.value,
                    });
                    this.removeExcessUnitRows();
                    this.resetUnitErrors();
                  }}
                  error={this.hasError(
                    "containerStatus"
                  )}
                />
                <Form.Field
                  control={Radio}
                  name="containerStatus"
                  label={languages("LABEL_EMPTY_CONTAINER")}
                  value={REQUEST_CONTAINER_STATUSES.EMPTY}
                  checked={containerStatus === REQUEST_CONTAINER_STATUSES.EMPTY}
                  onChange={(e: any, data: any) => {
                    this.onDisableError(
                      "containerStatus"
                    );
                    this.handleSetState({
                      ["containerStatus"]:
                        data.value,
                    });
                    this.resetUnitErrors();
                  }}
                  error={this.hasError(
                    "containerStatus"
                  )}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Header as="h3">
                <label className={styles.boxTitle}>
                  {languages("LABEL_CONTAINER_INFO")}
                </label>
                <Button
                  className="tablebtn c-tablebtn"
                  onClick={(e: any) => {
                    this.onAddUnitRow(e);
                  }}
                  disabled={
                    containerStatus === REQUEST_CONTAINER_STATUSES.FULL
                      ? true
                      : false
                  }
                >
                  <Icon name="add square" />
                </Button>
              </Header>
              <Table className="containersTable" striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {languages("LABEL_CONTAINERNUMBER")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_COMMODITY")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_CONTAINER_TYPE")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_SEAL")}
                    </Table.HeaderCell>
                    {containerStatus === REQUEST_CONTAINER_STATUSES.FULL ? (
                      <Table.HeaderCell>
                        {languages("LABEL_WEIGHT")}
                      </Table.HeaderCell>
                    ) : null}
                    <Table.HeaderCell>
                      {languages("LABEL_TEMPERATURE")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {languages("LABEL_REQUESTED_DISPATCH")}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {units &&
                    units.map((item: UnitFormData, i: number) => {
                      return (
                        <ContainerTableRow
                          unit={item}
                          key={i}
                          includeWeight={
                            containerStatus === REQUEST_CONTAINER_STATUSES.FULL
                          }
                          isOnlyRow={units.length === 1}
                          containerStatus={containerStatus}
                          containerTypes={containerTypes}
                          commodities={commodities}
                          onHandleValidation={(propName: string) => {
                            this.handleUnitValidation(
                              `${"units"}[${i}].${propName}`
                            );
                          }}
                          onDisableError={(propName: string) => {
                            this.onDisableError(
                              `${"units"}[${i}].${propName}`
                            );
                          }}
                          hasError={(propName: string) => {
                            return this.hasError(
                              `${"units"}[${i}].${propName}`
                            );
                          }}
                          onChange={(newUnit: UnitFormData) => {
                            const units = this.state.units.map(
                              (item: UnitFormData, j: number) => {
                                if (i === j) {
                                  return newUnit;
                                }
                                return item;
                              }
                            );
                            this.handleSetState({ units });
                          }}
                          onRemoveRow={(e: any) => {
                            this.onRemoveUnitRow(e, i);
                          }}
                          onContainerSelected={this.onContainerSelected}
                          timeInterval={timeInterval}
                        />
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <Header>
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_DRIVEN_FROM")}
                </label>
              </Header>
              <CustomerFormGroup
                customer={from}
                onChange={(customer: Customer) => {
                  this.handleSetState({
                    ["from"]: customer,
                  });
                }}
                onHandleValidation={(propName: string) => {
                  this.handleValidation(
                    `${"from"}.${propName}`
                  );
                }}
                onDisableError={(propName: string) => {
                  this.onDisableError(
                    `${"from"}.${propName}`
                  );
                }}
                hasError={(propName: string) => {
                  return this.hasError(
                    `${"from"}.${propName}`
                  );
                }}
                idSelectors={drivenFromIdSelectors}
              />
            </Grid.Column>
            <Grid.Column widtd={8}>
              <Header>
                <label className={`${styles.boxTitle} ${styles.required}`}>
                  {languages("LABEL_DRIVEN_TO")}
                </label>
              </Header>
              <CustomerFormGroup
                customer={to}
                onChange={(customer: Customer) => {
                  this.handleSetState({
                    ["to"]: customer,
                  });
                }}
                onHandleValidation={(propName: string) => {
                  this.handleValidation(
                    `${"to"}.${propName}`
                  );
                }}
                onDisableError={(propName: string) => {
                  this.onDisableError(
                    `${"to"}.${propName}`
                  );
                }}
                hasError={(propName: string) => {
                  return this.hasError(
                    `${"to"}.${propName}`
                  );
                }}
                idSelectors={drivenToIdSelectors}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Header>
                <label className={styles.boxTitle}>
                  {languages("LABEL_COMMENTS")}
                </label>
              </Header>
              <Textarea
                value={
                  this.state[
                    "requestNotes"
                  ]
                }
                placeholder={languages("TEXT_WRITE_COMMENTS")}
                onChange={(value: any) => {
                  this.handleSetState({
                    ["requestNotes"]:
                      value,
                  });
                }}
                maxLength={230}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Form.Field
                control={Button}
                onClick={this.onSubmit}
                primary
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {languages("LABEL_SUBMIT")}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}
