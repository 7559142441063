import * as angular from "angular";
import * as moment from "moment";
import { autorun } from "mobx";
import { $filter, $timeout } from "ngimport";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import ContainerService from "Services/ContainerService";
import ContainerDropdownService from "Containers/ContainerDropdownService";
import { $uiRouter } from "Bootstrap/angular";
import {
  UserService,
  TranslationService,
  SamskipNotify,
  ShipmentService
} from "Services";
import RouteStore from "../shared/stores/RouteStore";
import { MAIN_STATE } from "./index";
import { UtilityFunctions } from "Utilities";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { Set } from "es6-shim";
import GeneralLoader from "Utilities/GeneralLoader";

declare var GlobalConfig: GlobalConfig;
declare var $: any;

function containerController($scope: any, $transition$: Transition) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();
  const previousState = !RouteStore.previousState
    ? MAIN_STATE
    : RouteStore.previousState;

  const currentUser = UserService.getUserProfile();

  // Filters Selected
  $scope.initFilter = function initFilter() {
    const Filter: any = {};

    Filter.Type = { value: undefined, name: "Tegund", translate: "LABEL_TYPE" };
    Filter.Load = {
      value: undefined,
      name: "Lestun",
      translate: "FILTER_LOAD"
    };
    Filter.Location = {
      value: undefined,
      name: "Staðsetning",
      translate: "LABEL_LOCATION"
    };
    Filter.Discharge = {
      value: undefined,
      name: "Losun",
      translate: "FILTER_DISCHARGE"
    };
    Filter.Receipt = {
      value: undefined,
      name: "Móttökustaður",
      translate: "FILTER_RECEIPT"
    };
    Filter.Delivery = {
      value: undefined,
      name: "Afhendingastaður",
      translate: "LABEL_DELIVERY"
    };
    Filter.statusSelection = {
      value: [],
      name: "Status",
      translate: "LABEL_STATUS"
    };
    Filter.RentalStatus = {
      value: "ALL",
      name: "Leigustaða",
      translate: "FILTER_LEASESTATUS"
    };
    Filter.Search = {
      value: undefined,
      name: "Leit",
      translate: "FILTER_SEARCH"
    };

    return Filter;
  };

  $scope.orderDropdownTypes = [
    { pred: "Number", name: "Gámanúmer", translate: "LABEL_CONTAINERNUMBER" },
    {
      pred: "JobReference",
      name: "Bókunarnúmer",
      translate: "LABEL_BOOKINGNUMBER"
    },
    {
      pred: "DetentionDayCount",
      name: "Gámaleiga",
      translate: "LABEL_DETENTION"
    },
    {
      pred: "DemurrageDayCount",
      name: "Stæðaleiga",
      translate: "LABEL_DEMURRAGE"
    },
    { pred: "Type", name: "Tegund", translate: "LABEL_TYPE" },
    { pred: "ShipperName", name: "Sendandi", translate: "LABEL_SHIPPER" },
    { pred: "ConsigneeName", name: "Móttakandi", translate: "LABEL_CONSIGNEE" },
    { pred: "Location", name: "Staðsetning", translate: "FILTER_LOCATION" }
  ];

  $scope.setOrderPredicate = function setOrderPredicate(pred: string) {
    $scope.selectedPredicate = pred;
    $scope.order();
  };

  $scope.Filter = $scope.initFilter();
  $scope.FilterBase = $scope.initFilter();

  $scope.statusSelection = [];
  $scope.OldServiceWeb = GlobalConfig.ENV.OldServiceWeb;
  $scope.resultText = currentStateParams.resultText;

  $scope.errors = {
    search: false
  };

  // Search query
  if (currentStateParams.query !== undefined) {
    $scope.query = currentStateParams.query;
  }
  $scope.containers = [];

  autorun(() => {
    const listViewSize = UserService.getWebSettingObservable(
      USERWEBSETTINGS.SelectedListViewSize
    );
    if (!listViewSize) return;
    $scope.listViewSize = UtilityFunctions.getListViewSizeClass(
      listViewSize.StringValue
    );
  });

  dataLoader(currentState, currentStateParams);

  // pagination
  $scope.totalContainers = 0;
  $scope.currentPage = 1;
  $scope.maxSize = 3;

  /*
   * Items Per Page:
   * Everything regarding how many items to display per page
   */

  // Default value
  $scope.itemsPerPage = 10;

  // Get the settings for how many items per list page to display
  const settingsItemsPerListPage = UserService.getLocalWebSettingValue(
    USERWEBSETTINGS.ItemsPerListPage
  );
  if (settingsItemsPerListPage) {
    $scope.itemsPerPage = settingsItemsPerListPage;
  }

  // When items per page has changed, this event is triggered
  // which lets us save settings and fade list in and out
  $scope.$on("itemsPerPageChanged", (event: any, value: any) => {
    // Save the selection on the server
    UserService.setWebSetting(USERWEBSETTINGS.ItemsPerListPage, value);

    // Display loading animation for list
    // Refactor this jQuery shit
    (angular.element(".js-Tile-wrapper") as any).fadeTo(100, 0);
    (angular.element(".loaderContainerList") as any)
      .fadeTo(300, 1)
      .delay(600)
      .fadeTo(300, 0, () => {
        (angular.element(".js-Tile-wrapper") as any).fadeTo(100, 1);
      });
  });

  $scope.checkparams = function checkparams() {
    // Ready for delivery
    if (currentState === "containers_ready") {
      if ($scope.statusSelection.indexOf(5) === -1) {
        $scope.statusSelection.push(5);
      }
      $scope.fromMyPage = true;
    }

    if (currentState === "containers_lease") {
      $scope.Filter.RentalStatus.value = "1";
    } else if (currentState === "containers_lease_pending") {
      $scope.Filter.RentalStatus.value = "2";
    } else if (currentState === "containers_lease_last") {
      SamskipNotify.displayError("This feature has not been implemented");
    }
  };

  $scope.checkparams();

  UtilityFunctions.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));

  const orderBy = $filter("orderBy");
  // myArray.map(function (e) { return e.hello; }).indexOf('stevie');

  $scope.hasShipRole = UserService.isAuthorized(1001);

  // Search containers
  // query: Search query string
  // fromOther: boolean stating whether we're coming from another state
  $scope.search = function search(query?: string) {
    if (query && query.length < 4) {
      $scope.errors.search = true;
      return;
    }
    if (previousState === undefined) return;
    if (
      currentState === "containers_search" &&
      (query == null || query.length <= 0)
    ) {
      $state.go("containers");
    } else {
      $state.go("containers_search", { query });
    }
  };

  $scope.filters = function filters(item: any) {
    let addResult = true;

    if ($scope.Filter.statusSelection.value.length > 0) {
      for (let i = 0; i < $scope.Filter.statusSelection.value.length; i += 1) {
        if (item.TileStatus !== $scope.Filter.statusSelection.value[i]) {
          addResult = false;
        } else {
          addResult = true;
          break;
        }
      }
    }

    if (
      $scope.Filter.Type.value !== "" &&
      $scope.Filter.Type.value !== null &&
      $scope.Filter.Type.value !== undefined
    ) {
      if (
        item.Type === null ||
        item.Type.toUpperCase().indexOf(
          $scope.Filter.Type.value.toUpperCase()
        ) === -1
      ) {
        addResult = false;
      }
    }
    if (
      $scope.Filter.Load.value !== "" &&
      $scope.Filter.Load.value !== null &&
      $scope.Filter.Load.value !== undefined &&
      $scope.Filter.Load.value.length > 0
    ) {
      let foundPOL = false;
      for (let i2 = 0; i2 < $scope.Filter.Load.value.length; i2 += 1) {
        if ($scope.Filter.Load.value[i2] === "0" && item.LoadName === null) {
          foundPOL = true;
          break;
        }
        if (
          !(
            item.LoadName === null ||
            item.LoadName.toUpperCase().indexOf(
              $scope.Filter.Load.value[i2].toUpperCase()
            ) === -1
          )
        ) {
          foundPOL = true;
          break;
        }
      }
      if (!foundPOL) addResult = false;
    }

    if (
      $scope.Filter.Location.value !== "" &&
      $scope.Filter.Location.value !== null &&
      $scope.Filter.Location.value !== undefined
    ) {
      if (
        item.Location === null ||
        item.Location.toUpperCase().indexOf(
          $scope.Filter.Location.value.toUpperCase()
        ) === -1
      ) {
        addResult = false;
      }
    }
    if (
      $scope.Filter.Discharge.value !== "" &&
      $scope.Filter.Discharge.value !== null &&
      $scope.Filter.Discharge.value !== undefined &&
      $scope.Filter.Discharge.value.length > 0
    ) {
      let foundPOD = false;
      for (let i3 = 0; i3 < $scope.Filter.Discharge.value.length; i3 += 1) {
        if (
          $scope.Filter.Discharge.value[i3] === "0" &&
          item.DischargeName === null
        ) {
          foundPOD = true;
          break;
        }
        if (
          !(
            item.DischargeName === null ||
            item.DischargeName.toUpperCase().indexOf(
              $scope.Filter.Discharge.value[i3].toUpperCase()
            ) === -1
          )
        ) {
          foundPOD = true;
          break;
        }
      }
      if (!foundPOD) addResult = false;
    }

    if (
      $scope.Filter.Receipt.value !== "" &&
      $scope.Filter.Receipt.value !== null &&
      $scope.Filter.Receipt.value !== undefined &&
      $scope.Filter.Receipt.value.length > 0
    ) {
      let foundPLR = false;
      for (let i4 = 0; i4 < $scope.Filter.Receipt.value.length; i4 += 1) {
        if (
          $scope.Filter.Receipt.value[i4] === "0" &&
          item.ReceiptName === null
        ) {
          foundPLR = true;
          break;
        }
        if (
          !(
            item.ReceiptName === null ||
            item.ReceiptName.toUpperCase().indexOf(
              $scope.Filter.Receipt.value[i4].toUpperCase()
            ) === -1
          )
        ) {
          foundPLR = true;
          break;
        }
      }
      if (!foundPLR) addResult = false;
    }
    if (
      $scope.Filter.Delivery.value !== "" &&
      $scope.Filter.Delivery.value !== null &&
      $scope.Filter.Delivery.value !== undefined &&
      $scope.Filter.Delivery.value.length > 0
    ) {
      let foundPFD = false;
      for (let i5 = 0; i5 < $scope.Filter.Delivery.value.length; i5 += 1) {
        if (
          $scope.Filter.Delivery.value[i5] === "0" &&
          item.DeliveryName === null
        ) {
          foundPFD = true;
          break;
        }
        if (
          !(
            item.DeliveryName === null ||
            item.DeliveryName.toUpperCase().indexOf(
              $scope.Filter.Delivery.value[i5].toUpperCase()
            ) === -1
          )
        ) {
          foundPFD = true;
          break;
        }
      }
      if (!foundPFD) addResult = false;
    }
    if ($scope.Filter.RentalStatus.value !== "ALL") {
      // Not on rental
      if ($scope.Filter.RentalStatus.value === "0") {
        if (!(item.InRent === 0 && item.PendingRent === 0)) {
          addResult = false;
        }
      } else if ($scope.Filter.RentalStatus.value === "1") {
        // On rental today
        if (item.InRent === 0) {
          addResult = false;
        }
      } else if ($scope.Filter.RentalStatus.value === "2") {
        // On rental next week
        if (item.PendingRent === 0) {
          addResult = false;
        }
      }
    }

    if (angular.equals($scope.Filter, $scope.FilterBase)) {
      $(".js-ContentHeader-clearFilters").hide();
    } else {
      $(".js-ContentHeader-clearFilters").show();
    }

    return addResult;
  };

  UtilityFunctions.catToggle();
  UtilityFunctions.filterSlideControl(
    "js-Sidebar-triggerFilter",
    "js-Sidebar",
    "LABEL_OPEN_FILTER",
    "LABEL_CLOSE_FILTER"
  );

  if (
    currentStateParams.dateFrom !== undefined &&
    currentStateParams.dateTo !== undefined
  ) {
    $scope.dateFrom = currentStateParams.dateFrom;
    $scope.dateTo = currentStateParams.dateTo;
  }

  if (!$scope.dateFrom) {
    $scope.dateFrom = moment()
      .subtract(2, "weeks")
      .format("YYYY-MM-DD");
  }

  if (!$scope.dateTo) {
    $scope.dateTo = moment().format("YYYY-MM-DD");
  }

  UtilityFunctions.largeSpinner("largeSpinner");

  $scope.multiActionSelected = [];
  $scope.numberOfSelected = $scope.multiActionSelected.length;

  //  For sorting Containers
  $scope.order = function order(predicate: string, reverse: boolean = false) {
    $scope.containers = orderBy($scope.containers, predicate, reverse);
  };

  // Toggle status selection in filter
  $scope.toggleStatusSelection = function toggleStatusSelection(
    status: number
  ) {
    if (currentState === "containers_ready") {
      $state.go("containers");
    }

    $timeout(() => {
      const idx = $scope.Filter.statusSelection.value.indexOf(status);

      // is currently selected
      if (idx > -1) {
        $scope.Filter.statusSelection.value.splice(idx, 1);
      } else {
        // is newly selected
        $scope.Filter.statusSelection.value.push(status);
      }
    });
  };

  function calculateFreedays(freeDays: number, amountDays: number) {
    const obj: any = {};
    const lang = TranslationService.getSelectedLanguage();
    const translationStrategy = TranslationService.getTranslationStrategy(lang);
    if (!translationStrategy) return;

    if (freeDays == null || amountDays == null) {
      obj.cssClass = "";
      obj.text = "";
      obj.value = 0;
    } else if (isOnHire(freeDays, amountDays)) {
      const rent = -1 * (freeDays - amountDays);
      obj.cssClass = "u-colorRed";
      obj.text = `${rent} ${translationStrategy("LABEL_ONLEASE", rent)}`;
      obj.value = rent;
      obj.tooltip = translationStrategy("LABEL_FREEDAYS_EXCEEDED");
    } else {
      obj.cssClass = amountDays >= freeDays - 5 ? "u-colorRed" : "";
      obj.text = `${amountDays} ${TranslationService.translate("LABEL_OF")} \
            ${freeDays} ${translationStrategy("LABEL_FREEDAYS", freeDays)}`;
      obj.value = amountDays;
      obj.tooltip = translationStrategy(
        "LABEL_FREEDAYS_LEFT",
        freeDays - amountDays
      );
    }
    return obj;
  }

  // ************ Ordering and Filter functions ******************/

  $scope.selectedPredicate = {
    pred: "Number",
    name: "Gámanúmer",
    translate: "LABEL_CONTAINERNUMBER"
  };
  $scope.reverse = false;

  // For sorting bookings
  $scope.order = function order() {
    $scope.containers = orderBy(
      $scope.containers,
      $scope.selectedPredicate.pred,
      $scope.reverse
    );
  };

  $scope.removeFilter = function removeFilter(filter: any) {
    if (filter.name === "Leit") {
      $scope.search("");
    }

    angular.forEach($scope.Filter, (value, key) => {
      if (filter.name === value.name) {
        angular.forEach($scope.initFilter(), initValue => {
          if (initValue.name === value.name) {
            $scope.Filter[key] = initValue;
          }
        });
      }
    });
  };

  $scope.resetAllFilters = function resetAllFilters() {
    if ($scope.Filter.Search.value && $scope.Filter.Search.value.length > 1) {
      $scope.search("");
    }
    $scope.Filter = $scope.initFilter();
  };

  /* INTRO START */

  $scope.tutorialSteps = [
    {
      element: ".js-Sidebar-content",
      title: "Filter",
      content: "TUTOR_SEARCH",
      position: "left"
    },
    {
      element: ".js-Tile",
      title: "LABEL_RESULTS",
      content: "TUTOR_PANEL",
      position: "top"
    }
  ];

  /* INTRO END */

  // Function that displays voyage reference properly
  $scope.displayVoyageReference = function displayVoyageReference(
    container: any
  ) {
    let str;
    if (container.LoadVoyageReference === container.DischVoyageReference) {
      str = container.LoadVoyageReference;
    } else {
      str = `${container.LoadVoyageReference} - ${container.DischVoyageReference}`;
    }

    return str;
  };

  $scope.samExcel = {};

  $scope.samExcel.excelButtonTitle = function excelButtonTitle() {
    return "Excel";
  };

  // Downloads container list in Excel
  // API responds with a Excel file
  $scope.samExcel.downloadExcel = function downloadExcel() {
    if (!currentUser) return;
    const selectedCompany = UserService.getSelectedCompany();
    const partnerCode = selectedCompany.PartnerCode;

    return ContainerService.containersInExcel(
      partnerCode,
      $scope.filteredContainers
    );
  };

  $scope.samExcel.excelFileName = function excelFileName() {
    return `ContainerList.${$scope.dateFrom}-${$scope.dateTo}`;
  };

  $scope.getContainerMenuItems = function getContainerMenuItems(
    container: any
  ) {
    return ContainerDropdownService.getItemsForContainer(container);
  };

  /* RequestTypes for Containers */

  // load actions for bookings
  $scope.Actions = [];
  ShipmentService.getBookingActionsList().then((data: any) => {
    const hiddenTypes: any = {
      REQUESTTYPE_CHNGCUSTCLR: true,
      REQUESTTYPE_CLAIMREPORT: true
    };

    angular.forEach(data, Action => {
      if (!hiddenTypes[Action.Key] && Number(Action.ForContainer) === 1) {
        if (!UserService.isEmployeeOrAgent()) {
          // if customer.
          if (Action.ForCustomer !== "0") {
            // add menuitem if it is for customer.
            Action.text = "transl_missing";
            Action.href = "";
            Action.click = `sendMultiActionSelect(\'${Action.Site}\')`;
            Action.translate = Action.Key;
            $scope.Actions.push(Action);
          }
        } else {
          Action.text = "transl_missing";
          Action.href = "";
          Action.click = `sendMultiActionSelect(\'${Action.Site}\')`;
          Action.translate = Action.Key;
          $scope.Actions.push(Action);
        }
      }
    });
  });

  /* RequestTypes for Containers End*/

  function setLocationFilterOptions(containersArr: any[]) {
    $scope.locationFilterOptions = arrayToPlaces(containersArr, {
      PLR: "ReceiptName",
      POL: "LoadName",
      POD: "DischargeName",
      PFD: "DeliveryName"
    });

    /**
     * Convenience method to go through collection and map to a
     * point-type object with an array of points that match.
     * Example:
     * {
     *  POL: {
     *    place: 'Reykjavík',
     *    label: 'Reykjavik' }
     *  }, ...
     * @param array Container collection
     * @param obj Object to structure collection to
     */
    function arrayToPlaces(array: any, obj: {}) {
      const dict = {};
      Object.keys(obj).forEach(item => {
        const itemSet: any = new Set([...array.map((i: any) => i[obj[item]])]);
        dict[item] = [...itemSet].map((s: any) => {
          return {
            place: s,
            label: s
          };
        });
      });

      return dict;
    }
  }

  // Check if a container is on hire
  function isOnHire(freeDays: number, amountDays: number) {
    return freeDays - amountDays < 0;
  }

  function handleData(data: any) {
    // some animation
    $(".loaderContainerList")
      .clearQueue()
      .fadeTo(400, 0, () => {
        $(".js-DisplayList").fadeTo(500, 1, () => {
          $(".js-ListPage-box").trigger("heightChange");
        });
      });

    // Go through the result and calculate rent/
    for (const item of data) {
      item.Number = item.ContainerNumber;
      item.InRent = 0;
      item.PendingRent = 0;

      item.rentDemObj = calculateFreedays(
        item.DemurrageFreedays,
        item.DemurrageDayCount
      );
      item.rentDetObj = calculateFreedays(
        item.DetentionFreedays,
        item.DetentionDayCount
      );
      item.DemurrageDayCount = item.rentDemObj.value;
      item.DetentionDayCount = item.rentDetObj.value;

      if (item.DemurrageDayCount !== 0 || item.DetentionDayCount !== 0) {
        if (
          isOnHire(item.DemurrageFreedays, item.DemurrageDayCount) ||
          isOnHire(item.DetentionFreedays, item.DetentionDayCount)
        ) {
          item.InRent = 1;
        } else {
          item.PendingRent = 1;
        }
      }

      item.key = item.ContainerNumber;

      item.Type = `${item.Size}${item.Type}`;

      item.Location =
        item.Location === "SEAPOOL"
          ? item.TrackingVoyageReference
          : item.LocationName;

      if (!item.JobReference) {
        item.JobStatus = 80;
        const company = UserService.getSelectedCompany();
        item.ShipperName = company.FullName;
        item.ConsigneeName = company.FullName;
      }
    }

    $scope.containers = data;

    setLocationFilterOptions($scope.containers);

    $scope.Filter = $scope.initFilter();

    $scope.order();

    // pagination
    $scope.totalContainers = $scope.containers.length;
    $scope.currentPage = 1;

    $scope.$digest();
  }

  function searchContainers(query?: string) {
    if (!currentUser) return;
    const selectedCompany = UserService.getSelectedCompany();
    if ($scope.inListAPICall) return;
    if (query === undefined || query.length < 1) {
      $state.go(MAIN_STATE);
      return;
    }
    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderContainerList").fadeTo(400, 1);
    $scope.inListAPICall = true;
    GeneralLoader.increase();
    ContainerService.containerSearch(
      selectedCompany.PartnerCode,
      query.toUpperCase()
    )
      .then(handleData)
      .then(() => {
        $scope.inListAPICall = false;
        UtilityFunctions.setListSizes(
          UserService.getLocalWebSettingValue(
            USERWEBSETTINGS.SelectedListViewSize
          )
        );
        GeneralLoader.decrease();
      })
      .catch(() => {
        GeneralLoader.decrease();
      });
  }

  function getContainerList() {
    if (!currentUser) return;
    const selectedCompany = UserService.getSelectedCompany();
    if ($scope.inListAPICall) return;

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderContainerList").fadeTo(400, 1);
    $scope.query = undefined;
    $scope.containers = [];
    $scope.inListAPICall = true;

    GeneralLoader.increase();
    ContainerService.getContainerList(selectedCompany.PartnerCode)
      .then(handleData)
      .then(() => {
        GeneralLoader.decrease();
        $scope.inListAPICall = false;
        $scope.$apply();
      })
      .catch(() => {
        $(".loaderContainerList").hide(200);
        GeneralLoader.decrease();
      });
  }

  /**
   * Data loader
   */
  function dataLoader(stateName: string, stateParams: object) {
    const stateFunctions = [
      {
        states: [
          "containers",
          "containers_registered",
          "containers_ready",
          "containers_lease",
          "containers_lease_pending",
          "containers_lease_last"
        ],
        loader: () => {
          getContainerList();
        }
      },
      {
        states: ["containers_search"],
        loader: () => {
          searchContainers(currentStateParams.query);
        }
      }
    ];

    runDataLoader();

    function runDataLoader() {
      stateFunctions
        .filter(item => {
          if (item && item.states.indexOf(stateName) !== -1) return item;
        })
        .map(item => item.loader)
        .forEach(fn => {
          fn();
        });
    }
  }
}

containerController.$inject = ["$scope", "$transition$"];

export default containerController;
