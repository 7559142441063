import * as React from "react";
import { Input, DropdownSearchAsync } from "Component";
import { Form, DropdownItemProps, Grid } from "semantic-ui-react";
import "ts-nameof";
import { CompanyService, languages } from "Services";
import * as _ from "lodash";
import { ArrayUtilities } from "Utilities";

export default class CustomerFormGroup extends React.Component<any, {}> {
  state = {
    customers: [] as Customer[],
    customerOptions: [] as DropdownItemProps[],
    searchQuery: ""
  };

  componentDidUpdate = (prevProps: any) => {
    if (
      this.props.customer.SSN !== "" &&
      this.props.customer.SSN !== this.state.searchQuery
    ) {
      this.setState({ searchQuery: this.props.customer.SSN });
    }
    if (
      this.props.customer.SSN === "" &&
      prevProps.customer.SSN !== "" &&
      (this.props.customer.Name === "" && prevProps.customer.Name !== "")
    ) {
      this.setState({ searchQuery: "" });
    }
  };

  searchForCustomers = async (searchString: string) => {
    const customersUnOrdered = await CompanyService.searchCustomers(
      encodeURIComponent(searchString.trim())
    );

    // Show first entries (at the top of the array) that match the customers' names,
    // i.e. with regards to the 'searchString'
    const customers = ArrayUtilities.unique(
      customersUnOrdered
        .filter(
          data =>
            JSON.stringify(data.Name)
              .toLowerCase()
              .indexOf(searchString.toLowerCase()) !== -1
        )
        .concat(customersUnOrdered)
    );

    const customerOptions = _.map(
      customers,
      (customer: Customer, i: number) => {
        return {
          key: i,
          content: this.createListItem(customer),
          value: i
        } as DropdownItemProps;
      }
    );
    this.setState({ customers, customerOptions });
  };

  createListItem = (customer: Customer) => {
    return (
      <Grid>
        <Grid.Column width={6}>{customer.Name}</Grid.Column>
        <Grid.Column width={4}>{customer.SSN}</Grid.Column>
        <Grid.Column width={6}>{customer.Address}</Grid.Column>
      </Grid>
    );
  };

  onCustomerSelected = async (value: string) => {
    const customer = this.state.customers[value];
    if (customer) {
      this.props.onChange(customer);
      for (const prop in customer) {
        this.props.onDisableError(prop);
      }
      this.setState({
        customers: [customer],
        customerOptions: [
          {
            value,
            key: customer.SSN,
            content: this.createListItem(customer)
          } as DropdownItemProps
        ]
      });
    }
  };

  onChange = (propName: string, value: any) => {
    const customer = _.clone(this.props.customer);
    customer[propName] = value;
    this.props.onChange(customer);
  };

  onBlur = (propName: string) => {
    const { customer, onHandleValidation } = this.props;
    this.onChange(propName, customer[propName].trim());
    onHandleValidation(propName);
  };

  render() {
    const { customer, onDisableError, hasError, idSelectors } = this.props;
    const { customerOptions, searchQuery } = this.state;

    return (
      <React.Fragment>
        <Form.Field
          type="text"
          control={DropdownSearchAsync}
          onChange={(value: string) => this.onCustomerSelected(value)}
          placeholder={languages("TEXT_SSN_OR_NAME")}
          search={this.searchForCustomers}
          options={customerOptions}
          onClearOptions={() => {
            this.setState({ customers: [], customerOptions: [] });
          }}
          onClearValue={() => {
            this.onChange("SSN", "");
          }}
          minCharacters={3}
          onBlur={() => this.onBlur("SSN")}
          onFocus={() => {
            onDisableError("SSN");
          }}
          error={hasError("SSN")}
          lazyLoad={true}
          searchQuery={searchQuery}
          onSearchChange={(value: string) =>
            this.setState({ searchQuery: value })
          }
          maxitems={20}
        ></Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <Input
              value={customer.Name}
              type="text"
              onChange={(value: any) =>
                this.onChange("Name", value)
              }
              placeholder={languages("LABEL_NAME")}
              onBlur={() => this.onBlur("Name")}
              onFocus={() => {
                onDisableError("Name");
              }}
              error={hasError("Name")}
              id={idSelectors.name}
              name="name"
              autoComplete="name"
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={customer.Address}
              type="text"
              onChange={(value: any) =>
                this.onChange("Address", value)
              }
              placeholder={languages("LABEL_ADDRESS")}
              onBlur={() => this.onBlur("Address")}
              onFocus={() => {
                onDisableError("Address");
              }}
              error={hasError("Address")}
              id={idSelectors.address}
              name="address"
              autoComplete="address"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Input
              value={customer.City}
              type="text"
              onChange={(value: any) =>
                this.onChange("City", value)
              }
              placeholder={languages("LABEL_PLACE")}
              onBlur={() => this.onBlur("City")}
              onFocus={() => {
                onDisableError("City");
              }}
              error={hasError("City")}
              id={idSelectors.city}
              name="city"
              autoComplete="city"
            />
          </Form.Field>
          <Form.Field>
            <Input
              value={customer.Postcode}
              type="text"
              onChange={(value: any) =>
                this.onChange("Postcode", value)
              }
              placeholder={languages("LABEL_ZIP_CODE")}
              onBlur={() =>
                this.onBlur("Postcode")
              }
              onFocus={() => {
                onDisableError("Postcode");
              }}
              error={hasError("Postcode")}
              id={idSelectors.zipcode}
              name="zipcode"
              autoComplete="zipcode"
            />
          </Form.Field>
        </Form.Group>
      </React.Fragment>
    );
  }
}
