import * as React from "react";
import * as ReactDOM from "react-dom";
import { reaction } from "mobx";
import { Tabs, Tab } from "react-bootstrap";
import SamskipResource from "SamskipResource";

import { ContainerService, TranslationService, UserService } from "Services";

import { languages } from "Services/TranslationService";

import Tile from "./Tile";
import { BookingHistory, TrackingHistory, Supplies } from "./tabs";
import GeneralLoader from "Utilities/GeneralLoader";

export default class ContainerSinglePage extends React.Component<
  any,
  ContainerSinglePageState
> {
  state: ContainerSinglePageState = {
    lang: TranslationService.getSelectedLanguage(),
    container: undefined,
    location: undefined,
    history: undefined,
    tracking: undefined
  };
  async componentWillMount() {
    const { containerID } = this.props.$stateParams;
    const { PartnerCode } = UserService.getSelectedCompanyObservable();

    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({ lang: TranslationService.getSelectedLanguage() })
    );

    reaction(
      () => UserService.getSelectedCompanyObservable(),
      () =>
        this.getContainerHistory(
          UserService.getSelectedCompanyObservable().PartnerCode
        )
    );

    GeneralLoader.increase();
    ContainerService.getContainer(containerID)
      .then((container: Container) => this.setState({ container }))
      .then(() => {
        GeneralLoader.decrease();
      })
      .catch(() => {
        GeneralLoader.decrease();
      });

    GeneralLoader.increase();
    ContainerService.getContainerLocation(containerID)
      .then((location: Location) => this.setState({ location }))
      .then(() => {
        GeneralLoader.decrease();
      })
      .catch(() => {
        GeneralLoader.decrease();
      });

    GeneralLoader.increase();
    ContainerService.getContainerTrackingHistory(containerID)
      .then((tracking: ContainerTrackingHistory[]) =>
        this.setState({ tracking })
      )
      .then(() => {
        GeneralLoader.decrease();
      })
      .catch(() => {
        GeneralLoader.decrease();
      });

    this.getContainerHistory(PartnerCode);
  }
  getContainerHistory(partnerCode: string) {
    const { containerID } = this.props.$stateParams;

    GeneralLoader.increase();
    ContainerService.getContainerHistory(containerID, partnerCode)
      .then((history: ContainerHistory[]) => this.setState({ history }))
      .then(() => {
        GeneralLoader.decrease();
      })
      .catch(() => {
        GeneralLoader.decrease();
      });
  }
  render() {
    const { container, location, history, tracking } = this.state;
    const position: any = tracking && tracking.length ? tracking[0] : tracking;

    return (
      <div className="SinglePage container-single">
        <Tile container={container} location={location} position={position} />

        <Tabs defaultActiveKey={1} animation={false} id="details-tab">
          <Tab eventKey={1} title={languages("LABEL_BOOKING_HISTORY")}>
            <BookingHistory history={history} />
          </Tab>
          <Tab eventKey={2} title={languages("LABEL_MOVEMENT_HISTORY")}>
            <TrackingHistory tracking={tracking} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
