import * as React from "react";
import {
  Table,
  DropdownItemProps,
  Button,
  Icon,
  Form,
  Grid
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import "ts-nameof";
import { Input, Dropdown, DropdownSearchAsync } from "Component";
import {
  UnitFormData,
  Commodity,
  ContainerType,
  DropdownItemPropsSearch
} from "./interfaces";
import { ShipmentService, languages, ContainerService } from "Services";
import moment from "moment";
import fridagar from "fridagar";
import { DateUtilities } from "Utilities";

const defaultMinTime = moment(DateUtilities.getCurrentDate())
  .hour(8)
  .minute(0)
  .second(0)
  .toDate();
const defaultMaxTime = moment(DateUtilities.getCurrentDate())
  .hour(17)
  .minute(0)
  .second(0)
  .toDate();

export default class ContainerTableRow extends React.Component<any, any> {
  state = {
    temperatureOptions: [] as DropdownItemProps[],
    commodityOptions: [] as DropdownItemProps[],
    containerTypeOptions: [] as DropdownItemPropsSearch[],
    containers: [] as Container[],
    containerOptions: [] as DropdownItemProps[],
    minTime: defaultMinTime,
    maxTime: defaultMaxTime,
    minDate: DateUtilities.getCurrentDate(),
    containerSelected: false
  };

  componentDidMount = () => {
    this.setCommodities();
    this.setContainerTypes();

    if (this.props.unit.Cargo) {
      this.setTemperatureOptions(this.props.unit.Cargo);
    }
    if (
      DateUtilities.isSameDay(
        this.props.unit.DateRequested,
        DateUtilities.getCurrentDate()
      )
    ) {
      if (this.props.unit.DateRequested < defaultMinTime) {
        this.onChange({
          ["DateRequested"]: defaultMinTime
        });
      } else if (this.props.unit.DateRequested > defaultMaxTime) {
        const nextDay = moment(defaultMinTime)
          .add(1, "day")
          .toDate();
        this.onChange({
          ["DateRequested"]: nextDay
        });
        this.setState({ minDate: nextDay });
      } else if (
        this.props.unit.DateRequested > defaultMinTime &&
        this.props.unit.DateRequested < defaultMaxTime
      ) {
        this.setState({
          minTime: DateUtilities.getNextIntervalFromDate(
            DateUtilities.getCurrentDate(),
            this.props.timeInterval
          )
        });
      }
    }
    if (this.props.unit.Container && this.props.unit.ContainerType) {
      this.setState({ containerSelected: true });
    }
  };

  componentDidUpdate = (prevProps: any) => {
    if (
      this.props.commodities !== prevProps.commodities ||
      this.props.containerTypes !== prevProps.containerTypes
    ) {
      this.setCommodities();
      this.setContainerTypes();
      this.setState({ temperatureOptions: [] });
    }

    if (this.props.unit.Temperature && !prevProps.unit.Temperature) {
      this.setTemperatureOptions(this.props.unit.Cargo);
    }

    if (!this.props.unit.Container && prevProps.unit.Container) {
      this.setState({ containerSelected: false });
    }
  };

  setTemperatureOptions = (value?: string) => {
    const commoditiesLoading = ShipmentService.getCommodities();
    commoditiesLoading.then((data: Commodity[]) => {
      const c = _.find(data, [
        "CommodityCode",
        value
      ]);
      if (c && c.LowerTemperature !== null && c.HigherTemperature !== null) {
        const t = ShipmentService.getTemperatures(
          c.LowerTemperature.toString(),
          c.HigherTemperature.toString()
        );
        t.then(list => {
          this.setState({
            temperatureOptions: list.map((item: any) => {
              return {
                key: item,
                text: item,
                value: item.toString()
              } as DropdownItemProps;
            })
          });
        });
      } else {
        this.setState({ temperatureOptions: [] });
      }
    });
  };

  setCommodities = () => {
    const { commodities } = this.props;
    this.setState({
      commodityOptions: _.map(commodities, (item: Commodity, i: number) => {
        return {
          key: i,
          text: item.Description,
          value: item.CommodityCode
        } as DropdownItemProps;
      })
    });
  };

  setContainerTypes = () => {
    const { containerTypes } = this.props;
    this.setState({
      containerTypeOptions: _.map(
        containerTypes,
        (item: ContainerType, i: number) => {
          return {
            key: i,
            text: this.createListItem([
              `(${item.ISOType})`,
              item.OwnerCode ? item.InnerDescription : item.Description
            ]),
            value: item.OwnerCode
              ? `${item.ISOType}.${item.OwnerCode}`
              : `${item.ISOType}`,
            searchvalues: [
              item.ISOType,
              item.OwnerCode ? item.InnerDescription : item.Description
            ]
          } as DropdownItemPropsSearch;
        }
      )
    });
  };

  onBlur = (propName: string) => {
    const { unit, onChange, onHandleValidation } = this.props;
    if (unit[propName]) {
      this.onChange({ [propName]: unit[propName].trim() });
    } else {
      onHandleValidation(propName);
    }
  };

  onContainerSelected = (value: any) => {
    const container = this.state.containers.find(
      container => container.ContainerNumber === value
    );
    if (container) {
      this.setTemperatureOptions();
      this.onChange({
        ["Container"]: container.ContainerNumber,
        ["ContainerType"]: container.ISOCode,
        ["Owner"]: container.ContainerCodeDecriptions ? container.ContainerCode : null,
        ["Cargo"]: "",
        ["Temperature"]: ""
      });
      this.props.onDisableError(
        "ContainerType"
      );
      this.props.onDisableError("Temperature");
      this.setState({
        containers: [container],
        containerOptions: [
          {
            key: container.ContainerNumber,
            content: this.createListItem([
              `(${container.ISOCode})`,
              container.ContainerNumber,
              container.LocationType === "C"
                ? languages(`LABEL_LOCATION_${container.Location}`)
                : container.Location
            ]),
            value: container.ContainerNumber
          } as DropdownItemProps
        ],
        containerSelected: true
      });
      this.props.onContainerSelected(container);
    }
  };

  searchForContainerType = (
    containerTypes: DropdownItemPropsSearch[],
    searchString: string
  ) => {
    return containerTypes.filter(item => {
      for (const value of item.searchvalues) {
        if (value && value.toLowerCase().includes(searchString.toLowerCase())) {
          return item;
        }
      }
    });
  };

  searchForContainers = async (searchString: string) => {
    const containers = await ContainerService.containerSearchByNumber(
      encodeURIComponent(searchString.trim()),
      ["FCR", "MCR"]
    );
    const containerOptions = _.map(
      containers,
      (container: Container, i: number) => {
        const text =
          container.LocationType === "C"
            ? languages(`LABEL_LOCATION_${container.Location}`)
            : container.Location;
        return {
          key: i,
          content: this.createListItem([
            `(${container.ISOCode})`,
            container.ContainerNumber,
            text
          ]),
          value: container.ContainerNumber
        } as DropdownItemProps;
      }
    );
    this.setState({ containers, containerOptions });
  };

  createListItem = (values: string[]) => {
    return (
      <React.Fragment>
        {values && values.map(item => <span key={item}>{item}</span>)}
      </React.Fragment>
    );
  };

  isAvailableDate = (date: Date) => {
    if (date.getDay() === 6 || date.getDay() === 0) {
      return false;
    }
    const holidays = fridagar.getHolidays(
      date.getFullYear(),
      date.getMonth() + 1
    );
    for (const day of holidays) {
      if (DateUtilities.isSameDay(day.date, date) && !day.halfDay) {
        return false;
      }
    }
    return true;
  };

  setTimeLimits = (date: Date) => {
    const holidays = fridagar.getHolidays(
      date.getFullYear(),
      date.getMonth() + 1
    );
    const holiday = holidays.find(item =>
      DateUtilities.isSameDay(item.date, date)
    );

    if (holiday && holiday.halfDay) {
      this.setState({
        maxTime: moment(DateUtilities.getCurrentDate())
          .hour(12)
          .minute(0)
          .toDate()
      });
    } else {
      this.setState({ maxTime: defaultMaxTime });
    }

    if (DateUtilities.isSameDay(date, DateUtilities.getCurrentDate())) {
      this.setState({
        minTime: DateUtilities.getNextIntervalFromDate(
          DateUtilities.getCurrentDate(),
          this.props.timeInterval
        )
      });
    } else {
      this.setState({ minTime: defaultMinTime });
    }
  };

  getContainerTypeValue = () => {
    const unit: UnitFormData = this.props.unit;
    if (unit.ContainerType) {
      if (unit.Owner) {
        return `${unit.ContainerType}.${unit.Owner}`;
      }
      return unit.ContainerType;
    }
    return null;
  };

  onChange = (object: any) => {
    const unit = {
      ..._.clone(this.props.unit),
      ...object
    };
    this.props.onChange(unit);
  };

  render() {
    const {
      unit,
      includeWeight,
      onRemoveRow,
      containerStatus,
      hasError,
      onDisableError,
      onHandleValidation,
      isOnlyRow,
      timeInterval
    } = this.props;
    const {
      commodityOptions,
      containerTypeOptions,
      containerOptions,
      minTime,
      maxTime,
      minDate,
      containerSelected
    } = this.state;

    return (
      <Table.Row>
        <Table.Cell width={3}>
          <Form.Field
            value={unit.Container}
            type="text"
            control={DropdownSearchAsync}
            onChange={(value: string) => this.onContainerSelected(value)}
            placeholder={languages("LABEL_CONTAINERNUMBER")}
            search={this.searchForContainers}
            options={containerOptions}
            onClearOptions={() => {
              this.setState({ containers: [], containerOptions: [] });
            }}
            onClearValue={() => {
              this.onChange({
                ["Container"]: ""
              });
            }}
            minCharacters={6}
            onBlur={() =>
              this.onBlur("Container")
            }
            onFocus={() =>
              onDisableError("Container")
            }
            error={hasError("Container")}
            lazyLoad={true}
            searchQuery={unit.Container}
            onSearchChange={(value: string) => {
              this.setState({ containerSelected: false });
              this.onChange({
                ["Container"]: value
              });
            }}
            maxcharacters={11}
            maxitems={20}
          ></Form.Field>
        </Table.Cell>
        <Table.Cell width={3}>
          <Form.Field>
            <Dropdown
              placeholder={languages("LABEL_CHOOSE")}
              options={commodityOptions}
              value={unit.Cargo}
              onChange={(value: any) => {
                this.setTemperatureOptions(value);
                onDisableError("Temperature");
                this.onChange({
                  ["Cargo"]: value,
                  ["Temperature"]: ""
                });
              }}
              clearable
              selectOnNavigation={false}
              selectOnBlur={true}
              error={hasError("Cargo")}
              onBlur={() =>
                onHandleValidation("Cargo")
              }
              onFocus={() =>
                onDisableError("Cargo")
              }
              search
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell width={3}>
          <Form.Field>
            <Dropdown
              placeholder={languages("LABEL_CHOOSE")}
              options={containerTypeOptions}
              value={this.getContainerTypeValue()}
              onChange={(value: any) => {
                const values = value.split(".");
                const containerType = values[0];
                const ownerCode = values[1] ? values[1] : "";
                this.onChange({
                  ["ContainerType"]: containerType,
                  ["Owner"]: ownerCode
                });
              }}
              clearable
              selectOnNavigation={false}
              selectOnBlur={true}
              error={hasError("ContainerType")}
              onBlur={() =>
                onHandleValidation(
                  "ContainerType"
                )
              }
              onFocus={() =>
                onDisableError("ContainerType")
              }
              search={this.searchForContainerType}
              disabled={containerSelected}
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell>
          <Form.Field
            value={unit.Seal1}
            type="text"
            control={Input}
            onChange={(value: any) =>
              this.onChange({
                ["Seal1"]: value
              })
            }
            placeholder={languages("LABEL_SEAL")}
            onBlur={() => this.onBlur("Seal1")}
          ></Form.Field>
        </Table.Cell>
        {includeWeight ? (
          <Table.Cell>
            <Form.Field
              value={unit.Weight}
              type="number"
              control={Input}
              onChange={(value: any) =>
                this.onChange({
                  ["Weight"]: value
                })
              }
              placeholder={languages("LABEL_WEIGHT")}
              error={hasError("Weight")}
              onBlur={() =>
                onHandleValidation("Weight")
              }
              onFocus={() =>
                onDisableError("Weight")
              }
            ></Form.Field>
          </Table.Cell>
        ) : null}
        <Table.Cell width={1}>
          <Form.Field>
            <Dropdown
              placeholder={languages("LABEL_CHOOSE")}
              options={this.state.temperatureOptions}
              value={unit.Temperature}
              onChange={(value: any) => {
                this.onChange({
                  ["Temperature"]: value
                });
              }}
              selectOnNavigation={false}
              selectOnBlur={true}
              error={hasError("Temperature")}
              onBlur={() =>
                onHandleValidation(
                  "Temperature"
                )
              }
              onFocus={() =>
                onDisableError("Temperature")
              }
              disabled={this.state.temperatureOptions.length === 0}
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell width={3}>
          <Form.Field>
            <DatePicker
              selected={unit.DateRequested}
              onChange={(date: Date) => {
                this.setTimeLimits(date);
                this.onChange({
                  ["DateRequested"]: date
                });
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={timeInterval}
              dateFormat="dd/MM/yyyy HH:mm"
              minDate={minDate}
              minTime={minTime}
              maxTime={maxTime}
              filterDate={this.isAvailableDate}
              className={
                hasError("DateRequested")
                  ? "error"
                  : ""
              }
              onBlur={() =>
                onHandleValidation(
                  "DateRequested"
                )
              }
              onFocus={() =>
                onDisableError("DateRequested")
              }
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell className="remove-row-button">
          <Button
            className="tablebtn"
            onClick={(e: any) => {
              onRemoveRow(e);
            }}
            disabled={containerStatus === "FULL" || isOnlyRow ? true : false}
          >
            <Icon name="minus square" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}
