import * as angular from "angular";
import { $uiRouter } from "Bootstrap/angular";
import ContainerController from "Containers/ContainerController";
import Container from "Containers/container";
import Search from "Containers/search";

import * as containerTemplate from "Containers/containers.html";
import { USER_ROLES } from "Constants/UserRoles";
import DriveRequestPage from "./driveRequests";
import { Transition } from "@uirouter/core";
import {
  CompanyService,
  ContainerService,
  RequestService,
  ShipmentService,
  UserService,
} from "Services";
import GeneralLoader from "Utilities/GeneralLoader";

export const MAIN_STATE: string = "containers";

class Containers {
  constructor() {
    const routes: any[] = [
      {
        name: MAIN_STATE,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_registered`,
        url: `/${MAIN_STATE}/registered`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_ready`,
        url: `/${MAIN_STATE}/ready`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_lease`,
        url: `/${MAIN_STATE}/lease`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_lease_pending`,
        url: `/${MAIN_STATE}/pending`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_lease_last`,
        url: `/${MAIN_STATE}/last`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_search`,
        url: `/${MAIN_STATE}/search/:query`,
        parent: "masterPage",
        template: `${containerTemplate}`,
        controller: ContainerController,
        controllerAs: "$ctrl",
        params: {
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_single`,
        url: `/${MAIN_STATE}/:containerID`,
        parent: "masterPage",
        component: Container,
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
          title: (params: any) => {
            return params.containerID;
          },
        },
      },
      {
        name: `${MAIN_STATE}_global_search`,
        url: `/${MAIN_STATE}/search`,
        parent: "masterPage",
        component: Search,
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_drive_requests`,
        url: `/${MAIN_STATE}/drive_requests?PrevReq&container`,
        parent: "masterPage",
        component: DriveRequestPage,
        resolve: {
          container: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              if (toStateParams.container) {
                return ContainerService.getContainer(toStateParams.container);
              }
              return null;
            },
          ],
          previousRequest: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              if (toStateParams.PrevReq) {
                return RequestService.getRequestByRequestID(
                  toStateParams.PrevReq
                );
              }
              return null;
            },
          ],
          commodities: [
            "$transition$",
            ($transition$: Transition) => ShipmentService.getCommodities(),
          ],
          containerTypes: [
            "$transition$",
            ($transition$: Transition) => ShipmentService.getContainerTypes(),
          ],
          selectedCompany: [
            "$transition$",
            ($transition$: Transition) => {
              return new Promise((resolve, reject) => {
                if (UserService.getSelectedCompany().CompanyRegNo) {
                  CompanyService.searchCustomers(
                    UserService.getSelectedCompany().CompanyRegNo
                  ).then((data) => resolve(data[0]));
                } else {
                  resolve();
                }
              });
            },
          ],
          departments: [
            "$transition$",
            ($transition$: Transition) => {
              const currentUser = UserService.getUserProfile();
              if (currentUser) {
                return ShipmentService.getUserDepartments(
                  currentUser.Access.UserID,
                  currentUser.Access.SubSys
                );
              }
            },
          ],
          samskipPartner: [
            "$transition$",
            ($transition$: Transition) => {
              return new Promise((resolve, reject) => {
                CompanyService.searchCustomers("4409861539").then((data) => {
                  const customer = data.find(
                    (item: any) => item.AddressType === "NATREG"
                  );
                  if (customer) {
                    customer.Postcode = "REYT";
                    resolve(customer);
                  }
                });
              });
            },
          ],
        },
        data: {
          roles: [USER_ROLES.SHIP, USER_ROLES.DOM],
        },
      },
    ];

    routes.forEach((item) => $uiRouter.stateRegistry.register(item));
  }
}

angular.module("serviceWebApp").run(Containers);
