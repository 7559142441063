import * as React from "react";
import * as ReactDOM from "react-dom";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { languages } from "Services/TranslationService.ts";

export default class SimpleTable extends React.Component<any, any> {
  options = {
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    withFirstAndLast: true,
    sizePerPage: 8,
    prePage: languages("LABEL_PREVIOUSPAGE"),
    nextPage: languages("LABEL_NEXTPAGE"),
    firstPage: languages("LABEL_FIRSTPAGE"),
    lastPage: languages("LABEL_LASTPAGE"),
  };

  render() {
    const { body, options } = this.props;

    const rows = Object.keys(body[0] || []).map((key: string, i: number) => {
      return (
        <TableHeaderColumn
          key={i}
          isKey={i === 0}
          dataSort
          dataField={key}
          {...(options && options[key])}
        >
          {languages(key)}
        </TableHeaderColumn>
      );
    });

    return (
      <BootstrapTable data={body} pagination options={this.options}>
        {rows}
      </BootstrapTable>
    );
  }
}
