import * as React from "react";
import * as ReactDOM from "react-dom";

import SamskipResource from "SamskipResource";

import Table from "Containers/container/Table";
import { languages } from "Services/TranslationService.ts";

export default class BookingHistory extends React.Component<
  BookingHistoryProps,
  any
> {
  state = {
    options: {
      LABEL_BOOKING: {
        width: "25%",
        dataFormat: (cell: any) => {
          return <a href={`shipments/${cell}`}> {cell} </a>;
        }
      },
      LABEL_VOYAGENUMBERSHORT: {
        width: "25%"
      },
      LABEL_SENDER: {
        width: "25%"
      },
      LABEL_CONSIGNEE: {
        width: "25%"
      }
    }
  };
  render() {
    const { history } = this.props;

    if (!history) return <i className="fa fa-spinner fa-pulse"></i>;

    const body = history.map((c: ContainerHistory) => {
      return {
        LABEL_BOOKING: c.JobReference,
        LABEL_VOYAGENUMBERSHORT: c.VoyageReference,
        LABEL_SENDER: c.ShipperFullName,
        LABEL_CONSIGNEE: c.ConsigneeFullName
      };
    });

    const element = <Table body={body} options={this.state.options} />;

    return body.length > 0 ? element : <h2>{languages("TEXT_NORESULTS")}</h2>;
  }
}
