import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";

import { languages } from "Services/TranslationService.ts";

export default class ContainerSinglePage extends React.Component<
  ContainerTileProps,
  {}
> {
  render() {
    const { container = {}, location = {}, position = {} } = this.props;
    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
    };

    return (
      <div className="ShipmentsSingleBooking-content smaller">
        <div className="row upper">
          <div className="col-md-2 col-sm-3">
            <span className="boxTitle">{languages("LABEL_CONTAINERNO")}</span>
            <div> {container["ContainerNumber"] || "-"} </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <span className="boxTitle">{languages("LABEL_TYPE")}</span>
            {container["Size"]}
            {container["Type"]}
            <div className="lower-info-string">
              {container["Description"]}{" "}
              {container["ISOCode"] ? `(${container["ISOCode"]})` : "-"}
            </div>
          </div>
          <div className="col-md-2">
            <span className="boxTitle">{languages("LABEL_EMPTY_WEIGHT")}</span>
            {(container["TareWeight"] || "0").toLocaleString("de-DE")} kg
          </div>
          <div className="col-md-2">
            <span className="boxTitle">
              {languages("LABEL_CARRYING_WEIGHT")}
            </span>
            {(container["PayloadWeight"] || "0").toLocaleString("de-DE")} kg
          </div>
          <div className="col-md-2">
            <span className="boxTitle">{languages("LABEL_TOTAL_WEIGHT")}</span>
            {`${(
              (container["TareWeight"] || 0) + (container["PayloadWeight"] || 0)
            ).toLocaleString("de-DE")} kg`}
          </div>
        </div>
        <div className="row lower">
          <div className="col-md-3 col-sm-3">
            <span className="boxTitle">{languages("LABEL_MYSTATUS")}</span>
            {position["ContainerStatus"]
              ? languages(`CONTAINERSTATUS_${position["ContainerStatus"]}`)
              : "-"}
          </div>
          <div className="col-md-3 col-sm-3">
            <span className="boxTitle">{languages("LABEL_DATE_STATUS")}</span>
            {position["StatusDate"]
              ? moment(position["StatusDate"]).format("DD.MM.YYYY")
              : "-"}
          </div>
          <div className="col-sm-2 col-md-3">
            <span className="boxTitle">
              {languages("LABEL_CURRENT_LOCATION")}
            </span>
            {location["Name"] || "-"}
          </div>
          <div className="col-sm-2 col-md-3">
            <span className="boxTitle">{languages("LABEL_PORT")}</span>
            {capitalize(location["Port"] || "-")}
          </div>
          <div className="col-md-2 col-sm-2">
            <span className="boxTitle">{languages("LABEL_COUNTRY")}</span>
            {capitalize(location["Country"] || "-")}
          </div>
        </div>
      </div>
    );
  }
}
