import * as React from "react";
import { reaction } from "mobx";
import styles from "./index.less";
import * as _ from "lodash";
import { Container, Header, Grid, Tab, TabPane } from "semantic-ui-react";
import "ts-nameof";
import { DriveRequestPageState } from "./interfaces";
import { TranslationService, languages } from "Services";
import DriveRequestForm from "./driveRequestForm";
import NoEquipmentRequestPane from "./noEquipmentRequestPane";

export default class DriveRequestPage extends React.Component<
  any,
  DriveRequestPageState
> {
  state: DriveRequestPageState = {
    lang: TranslationService.getSelectedLanguage()
  };

  componentDidMount = () => {
    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({ lang: TranslationService.getSelectedLanguage() })
    );
  };

  render() {
    const props = this.props as any;
    const panes = [
      {
        menuItem: languages("MENU_DRIVEREQUEST"),
        pane: {
          key: 1,
          content: <DriveRequestForm lang={this.state.lang} {...props} />
        }
      },
      {
        menuItem: languages("MENU_NOEQUIPMENTREQUEST"),
        pane: {
          key: 2,
          content: <NoEquipmentRequestPane lang={this.state.lang} />
        }
      }
    ];

    return (
      <Container className={styles.page}>
        <Tab panes={panes} renderActiveOnly={false} />
      </Container>
    );
  }
}
