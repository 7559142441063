export enum REQUEST_STATUSES {
  CREATED = 0,
  ONHOLD = 1,
  INPROGRESS = 2,
  CLOSED = 3,
  CANCELED = 4
}

export const REQUEST_CONTAINER_STATUSES = {
  FULL: "FULL",
  EMPTY: "EMPTY"
};

export const SG_REQUEST_TYPES = {
  EMPTYCNTEXP: "EMPTYCNTEXP",
  RELFCLIMP: "RELFCLIMP",
  TRUFWDIMPLCL: "TRUFWDIMPLCL",
  SPEEDDISCH: "SPEEDDISCH",
  DRREQVANINP: "DRREQVANINP",
  CORRECTION: "CORRECTION",
  IMPREADYNOTIFY: "IMPREADYNOTIFY",
  DRREQCNTIMP: "DRREQCNTIMP",
  TRUFWDIMPFCL: "TRUFWDIMPFCL",
  DELIVREQ: "DELIVREQ",
  FETCFULLCNTEXP: "FETCFULLCNTEXP",
  CUSTCLR: "CUSTCLR",
  BOOKINGQUERY: "BOOKINGQUERY",
  FETCHEMTYCNT: "FETCHEMTYCNT"
};
