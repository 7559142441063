import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";

import SamskipResource from "SamskipResource";

import Table from "Containers/container/Table";
import { languages } from "Services/TranslationService";
import { RequestService } from "Services";

declare var GlobalConfig: GlobalConfig;

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
};

export default class TrackingHistory extends React.Component<
  TrackingHistoryProps,
  any
> {
  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (this.props.tracking !== prevProps.tracking && this.props.tracking) {
      const body = this.props.tracking.map((c: ContainerTrackingHistory) => {
        if (c.ExternalReference) {
          return RequestService.getRequestVersion(
            parseInt(c.ExternalReference, 10)
          ).then((res: { Version: number }) => {
            c.ExternalVersion = res.Version;
            return c;
          });
        }
        c.ExternalVersion = 0;
        return c;
      });
      Promise.all(body).then(results => {
        this.setState({ tracking: results });
      });
    }
  };

  format = (cell: any, row: number) => {
    if (cell.version === 1) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${GlobalConfig.ENV.OldServiceWeb}/DisplayBookingInquiry.aspx?ReqNo=${cell.requestNumber}`}
        >
          {cell.requestNumber}
        </a>
      );
    }

    if (cell.version === 2) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${GlobalConfig.ENV.OldServiceWeb}/PDFDisplay.aspx?Req=${cell.requestNumber}`}
        >
          {cell.requestNumber}
        </a>
      );
    }

    return "-";
  };

  state = {
    tracking: [],
    options: {
      LABEL_DATE: {
        width: "20%",
        sortFunc: (a: any, b: any, order: string) => {
          const dateA = moment(a.LABEL_DATE, "DD.MM.YYYY HH:mm");
          const dateB = moment(b.LABEL_DATE, "DD.MM.YYYY HH:mm");

          return order === "desc" ? dateA.diff(dateB) : dateB.diff(dateA);
        }
      },
      LABEL_MYSTATUS: {
        width: "20%"
      },
      LABEL_MOVMENT_POSITION: {
        width: "20%"
      },
      LABEL_VOYAGENUMBERSHORT: {
        width: "20%"
      },
      LABEL_REQUESTNUMBERS: {
        width: "20%",
        dataFormat: this.format
      }
    }
  };

  render() {
    const { tracking } = this.state;

    if (!tracking || tracking.length === 0) {
      return <i className="fa fa-spinner fa-pulse"></i>;
    }

    const body = tracking.map((c: ContainerTrackingHistory) => {
      return {
        LABEL_DATE: moment(c.StatusDate).format("DD.MM.YYYY HH:mm"),
        LABEL_MYSTATUS: languages(`CONTAINERSTATUS_${c.ContainerStatus}`),
        LABEL_MOVMENT_POSITION: capitalize(c.LocalName || "-"),
        LABEL_VOYAGENUMBERSHORT: c.VoyageReference || "-",
        LABEL_REQUESTNUMBERS: {
          requestNumber: c.ExternalReference,
          version: c.ExternalVersion
        }
      };
    });

    const element = <Table body={body} options={this.state.options} />;

    return body.length > 0 ? element : <h2>{languages("TEXT_NORESULTS")}</h2>;
  }
}
